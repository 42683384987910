/*
 * Copyright 2021-23, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */
/**
 *  NavigationDataError will display when through catch error data loading failed from experience api
 */
export const NavigationDataError = 'Navigation data failed';

export const RecentlyViewedParticipantServiceError = 'We can\'t display your recently viewed data right now.';

export const NoRecentlyViewedParticipantMessage = 'You have no recently viewed participants.';
