
/*
 * Copyright 2021-2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import {
    LitElement,
    html,
    TemplateResult,
    nothing
} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { injectCss } from '@fmr-ap123285/dp-microfrontend-utils';
import ComponentStyles from './component.scss';
import '../utility-nav/component';
import '../header-nav/component';
import { Client, Menu, RootObject, UtilityNavbar } from '../../../models/content';
import { SimpleNavigationOnly } from '../../../constants/navbar.constants';
import { TaggingService } from '../../../services/tagging.service';

/**
 * FCNavBar component that extends the LitElement base class.
 * @memberOf FCNavBar
 */
@injectCss(ComponentStyles)
@customElement('fc-navbar')

export class FCNavBar extends LitElement {

    @property({ attribute: true })
    public navbarData: string = '';

    @property({ attribute: true })
    private clientId: string = '';

    private pageId: string = '';

    private utilityNav: UtilityNavbar | null = null;

    private navTabs: Menu | undefined = undefined;

    private clientList: Client[] = [];

    private simpleUtilityNavbar: boolean = false;

    private pageTitle: string | undefined = '';

    private user: string | undefined = '';

    private userType: string | undefined = '';

    private backToBookOfBusiness: string | undefined = '';

    private client: string | undefined = '';

    private bobContext: boolean = true;

    private experience: string | undefined = '';

    /**
     * CreateRenderRoot method : Removes the shadow dom
     * @memberOf FCNavBar
     */
    public createRenderRoot() {
        return this;
    }

    public connectedCallback() {
        super.connectedCallback();
        const navbarDataObject = JSON.parse(this.navbarData) as RootObject;
        this.user = navbarDataObject?.user;
        this.userType = navbarDataObject?.siteNav?.userType;
        const data = navbarDataObject?.siteNav?.navigationHeader;
        this.pageId = navbarDataObject.pageServices.pageId;
        this.utilityNav = data?.utilityNavbar;
        this.navTabs = data.menu;
        this.clientList = navbarDataObject.pageData.clientList!;
        this.client = navbarDataObject.client;
        if (this.client) {
            this.bobContext = false;
        }
        this.pageTitle = navbarDataObject?.pageData.pageTitle;
        this.backToBookOfBusiness = this.utilityNav.utilities?.find(
            (utility, i) =>
                utility.page.id.toLocaleLowerCase() === 'backtobookofbusiness'
        )?.page.label;

        const pageFeaturesList = navbarDataObject?.pageServices?.pageFeatures;
        this.experience = navbarDataObject.experience || '';

        for (const element of pageFeaturesList) {
            if (element.featureName === SimpleNavigationOnly) {
                this.simpleUtilityNavbar = true;
                break;
            }
        }
    }

    /**
     * The render function defines a template literal and
     * makes use of the utility navbar element using this below template as if it were part of the HTML
     * @memberOf FCNavBar
     */
    /* eslint-disable max-len*/
    public render(): TemplateResult {
        return html`
        <div class="header">
            <connect-navbar-utility-nav .utilityNav=${this.utilityNav} .bobContext = ${this.bobContext} .userType=${this.userType}
                .experience=${this.experience}></connect-navbar-utility-nav>
            ${this.user ? html `
            <div class="nav">
                ${this.backToBookOfBusiness ? html `
                    <div class="back">
                        <a id=${this.backToBookOfBusiness} href=${this.utilityNav!.logo.target} @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.54582 9.08842L8.81102 14.3536C9.13662 14.6792 9.13662 15.2071 8.81102 15.5327C8.48542 15.8583 7.95752 15.8583 7.63192 15.5327L0.943418 8.84422C0.617817 8.51862 0.617817 7.99072 0.943418 7.66512L7.63192 0.976622C7.95752 0.651022 8.48542 0.651022 8.81102 0.976622C9.13662 1.30222 9.13662 1.83012 8.81102 2.15572L3.54582 7.42092L15.8655 7.42092C16.3259 7.42092 16.6992 7.7942 16.6992 8.25467C16.6992 8.71514 16.3259 9.08842 15.8655 9.08842L3.54582 9.08842Z" fill="black"/>
                            </svg>
                        <span class="text">${this.backToBookOfBusiness}</span>
                        </a>
                    </div>
                `: nothing}
                <connect-navbar-header-nav .navTabs=${this.navTabs?.menuGroups} pageId=${this.pageId} pageTitle=${this.pageTitle}
                .clientList=${this.clientList} .client=${this.client} .bobContext = ${this.bobContext} .userType=${this.userType}></connect-navbar-header-nav>
            </div>
            `: nothing}
        </div>
        `;
    }
}
