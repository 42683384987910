/*
 * Copyright 2024, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import { FdAnalyticsService } from '@fmr-ap123285/dp-microfrontend-utils';
import { AnalyticsData } from '../constants/navbar.constants';

export class TaggingService {

    public static submitAnalytics(bobContext:boolean, userType:string|undefined, e: Event) {
        const eventTargetElement: Element = e?.target as Element;
        let tag: string = '';
        if(userType) {
            tag += userType + ':';
        }
        if(bobContext) {
            tag += 'Book of Business:';
        }
        tag += eventTargetElement.closest('a')!.id;
        const analyticsData = AnalyticsData;
        analyticsData.action_detail = tag;
        analyticsData.event_name = 'navbar action:' + tag;
        console.log('tag: ', tag);
        FdAnalyticsService.submitAnalytics(analyticsData);
    }

    public static submitAnalyticsDropdown(bobContext:boolean, userType:string|undefined, clientName:string) {
        let tag: string = '';
        if(userType) {
            tag += userType + ':';
        }
        if(bobContext) {
            tag += 'Book of Business:';
        }
        tag += 'dropdown:'+clientName;
        const analyticsData = AnalyticsData;
        analyticsData.action_detail = tag;
        analyticsData.event_name = 'user action:' + tag;
        console.log('tag: ', tag);
        FdAnalyticsService.submitAnalytics(analyticsData);
    }

}
