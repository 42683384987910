import { LitElement, html, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

declare global {
    interface HTMLElementTagNameMap {
        'psw-logo': PswLogo;
    }
}

@customElement('psw-logo')
export class PswLogo extends LitElement {
    public render(): TemplateResult {
        return html`
            <svg width="221" height="36"  fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6511_547)">
            <path d="M8.07398 29.075H0L6.46696 6H23.0167L21.4421 11.4755H12.9987L11.9166 15.4024H20.3599L18.9343
             20.4437H10.491L8.08046 29.075H8.07398Z" fill="#368727"/>
            <path d="M26.7556 29.075H18.7723L23.5027 12.2142H31.4859L26.7556 
            29.075ZM31.9201 10.6072H23.9433L25.2393 6H33.2161L31.9201 10.6072Z" fill="#368727"/>
            <path d="M47.459 29.075H39.6312L40.1885 27.1893C38.6139 28.5177 37.0004 
            29.4768 34.4343 29.4768C31.8683 29.4768 30.1964 28.2391 30.1964 25.6082C30.1964
            22.6988 31.3434 18.3702 33.0411 15.3376C34.2788 13.0826 36.5079 11.9032 39.1323
            11.9032C41.3873 11.9032 43.0591 12.8946 43.7071 14.1323L45.9362 6H53.9194L47.4525
            29.075H47.459ZM41.4585 17.2297C40.7458 17.2297 40.033 17.45 39.6312 18.435C39.0415 19.8606
            38.5167 21.2473 38.5167 22.6404C38.5167 23.2884 38.9832 23.8457 39.7543 23.8457C40.3699
            23.8457 40.7782 23.6578 41.1151 23.444L42.7221 17.9036C42.4435 17.5019 41.951 17.2232 41.4521 
            17.2232L41.4585 17.2297Z" fill="#368727"/>
            <path d="M69.9896 21.7138H58.9802C58.7664 22.608 58.5461 23.2301 58.5461 23.9429C58.5461
            24.2863 58.6044 25.1482 59.719 25.1482C60.8335 25.1482 61.326 24.714 61.7925 23.0746H69.6203C68.6288
            27.8697 62.9395 29.5092 59.4468 29.5092C54.7165 29.5092 51.0359 28.8288 51.0359 24.2799C51.0359
            21.7721 52.3059 17.6315 53.9713 15.4348C55.98 12.8039 59.4792 11.819 62.6932 11.819C67.6439
            11.819 70.8579 12.8363 70.8579 17.0483C70.8579 18.0721 70.4238 20.3271 69.9896
            21.7203V21.7138ZM62.0064 16.0568C61.0149 16.0568 60.3345 16.7372 59.9004 18.435H63.1792C63.27
            18.098 63.3931 17.5667 63.3931 17.2621C63.3931 16.3355 62.8358 16.0568 61.9999 16.0568H62.0064Z" fill="#368727"/>
            <path d="M78.0053 29.075H70.022L76.489 6H84.4723L78.0053 29.075Z" fill="#368727"/>
            <path d="M88.5546 29.075H80.5714L85.3017 12.2142H93.285L88.5546 29.075ZM93.7191 10.6072H85.7358L87.0318
            6H95.0151L93.7191 10.6072Z" fill="#368727"/>
            <path d="M108.066 12.2142L108.759 16.018H102.648L100.854 22.4201C100.698 22.9126 100.575 23.5347
            100.575 23.9364C100.575 24.6816 100.886 24.9278 101.968 24.9278H103.672L102.499 29.075H95.3845C93.0323
            29.075 92.138 27.9281 92.138 26.1979C92.138 25.4851 92.2287 24.714 92.4815 23.8457L96.81 8.47533H104.793L103.744
            12.2207H108.072L108.066 12.2142Z" fill="#368727"/>
            <path d="M118.835 12.2142L114.507 21.5583L114.63 12.2142H106.556L108.105 29.075C107.91 30.06 107.58 30.6172
            106.964 30.8959C106.284 31.2004 104.754 31.1421 104.217 31.1162H104.119L102.849 35.704H108.299C111.513 35.704 113.094
             34.0905 115.382 30.5071L127.013 12.2272H118.848L118.835 12.2142Z" fill="#368727"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M212.692 10.3927C212.692 8.17566 214.498 6.37805 216.698
            6.37805C218.898 6.37805 220.692 8.17566 220.692 10.3927C220.692 12.6097 218.898 14.4074 216.698 
            14.4074C214.486 14.4074 212.69212.5978 212.692 10.3927ZM213.409 10.3921C213.409 12.2137 214.88 
            13.6877 216.698 13.6877C218.515 13.6877 219.986 12.2137 219.986 10.3921C219.986 8.57056 218.515 
            7.09653 216.698 7.09653C214.88 7.09653 213.409 8.57056 213.409 10.3921Z" fill="#368727" fill-opacity="0.9"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M216.697 8.51056C217.552 8.51056 217.953
            8.90294 217.953 9.61346C217.953 10.2497 217.582 10.5573 217.099 10.6209L218.025 12.1692H217.335L216.45 
            10.6739H215.976V12.1692H215.358V8.51056H216.697ZM215.987 9.08539V10.1034V10.1141H216.46C216.862 
            10.1141 217.304 10.0822 217.304 9.59442C217.304 9.09599 216.862 
            9.07478 216.46 9.08539H215.987Z" fill="#368727" fill-opacity="0.9"/>
            <path d="M154.723 19.0461H149.646V28.3999H147V6.61538H154.477C158.2 6.61538 161.861 
            8.0923 161.861 12.7076C161.861 16.923 158.631 19.0461 154.723 19.0461ZM154.569 
            8.89229H149.646V16.7692H154.661C156.877 16.7692 159.031 15.5384 159.031 12.7384C159.031
            9.93844 156.754 8.89229 154.569 8.89229Z" fill="#368727"/>
            <path d="M178.575 7.93845V10.9846C176.544 9.19998 174.513 8.1846 172.39 8.1846C169.836 
            8.1846 168.083 9.63075 168.083 12.0615C168.083 14.5846 169.959 15.4461 172.575 
            16.2153C176.606 17.4153 179.036 19.0153 179.036 22.6153C179.036 26.7999 175.559
            29.0152 171.744 29.0152C169.129 29.0152 166.913 28.1537 165.067 
            26.9845V23.7845C167.529 25.8768 169.713 26.8614 171.775 26.8614C174.483
            26.8614 176.267 25.2614 176.267 22.8307C176.267 20.2461 174.083 19.2922 171.529 
            18.523C167.559 17.3845 165.344 15.7538 165.344 12.123C165.344
            8.21537 168.575 6 172.544 6C175.006 6 176.975 6.8 178.575 7.93845Z" fill="#368727"/>
            <path d="M195.967 10.4615L190.521 28.3999H187.874L181.351 6.61538H184.244L189.259 24.4614H189.351L194.613
            6.61538H197.444L202.828 24.4614H202.921L207.936 6.61538H210.705L204.151 
            28.3999H201.536L196.028 10.4615H195.967Z" fill="#368727"/>
            <defs>
            <clipPath id="clip0_6511_547">
            <rect width="127" height="29.704" fill="white" transform="translate(0 6)"/>
            </clipPath>
            </defs>
            </svg>
        `;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }
}
