import { PlanAdvisorExperience } from '../app/constants/navbar.constants';

export const Environment = {

    getReqId() {
        // eslint-disable-next-line
        return (<any>window).fsreqid;
    },

    getNavigationDataUrl(pageId: string, realm: string) {
        // eslint-disable-next-line
        if(realm.toLocaleLowerCase().startsWith(PlanAdvisorExperience)) {
            return (<any>window).location.origin +'/planadvisor/go/to/pages/'+pageId+'/layout';
        }
        return (<any>window).location.origin +'/plansponsor/go/to/pages/'+pageId+'/layout';
    },

    // eslint-disable-next-line
    recentlyViwewedParticipantsDataUrl: (<any>window).location.origin + '/plansponsor/participantsearch/api/participants/recently-viewed',

    // eslint-disable-next-line
    participantChosenUrl: (<any>window).location.origin + '/plansponsor/participantsearch/api/participant-chosen',

    // Url to update preferences
    updatePreferencesUrl: (<any>window).location.origin + '/plansponsor/participantsearch/api/preferences/',

    // Url to get txn token
    txnTokenUrl: (<any>window).location.origin + '/plansponsor/participantsearch/api/txntoken',

    // participant search landing page
    partSearchLanding: (<any>window).location.origin +'/plansponsor/go/to/partSearchLanding/#partSearchLanding',

    // participant search options api
    participantSearchOptionsDataUrl: (<any>window).location.origin +'/plansponsor/participantsearch/api/options?destinations=true'


};
