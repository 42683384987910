/*
 * Copyright 2024, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import { LitElement, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

declare global {
    interface HTMLElementTagNameMap {
        'connect-logo': ConnectLogo;
    }
}

@customElement('connect-logo')
export class ConnectLogo extends LitElement {

    @property({ type: String })
    public experience: string | undefined = '';

    public connectedCallback() {
        super.connectedCallback();
        // this.advisorExp = this.experience.toLocaleLowerCase();
    }

    public render(): TemplateResult {
        return this.experience!.toLowerCase().indexOf('_cwv') < 0 ? html`
        <svg width="344" height="38" viewBox="0 0 344 38" fill="none" xmlns="http://www.w3.org/2000/svg" alt="Fidelity Connect Logo">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84848 31.5248H0.791016L7.24346 7.97998H23.7677L22.1951 
            13.5662H13.7605L12.6833 17.5749H21.1179L19.6959 22.7181H11.2614L8.84848 31.5248Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8983 12.6828H24.9269L26.2303 7.97998H34.1909L32.8983 
            12.6828ZM27.7384 31.5248H19.7671L24.496 14.3239H32.4674L27.7384 31.5248Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.9992 19.4354C41.2883 19.4354 40.5774 19.6582 40.1788 
            20.6671C39.5864 22.1184 39.0693 23.5386 39.0693 24.9588C39.0693 25.6225 39.5325 26.1899 40.2973 
            26.1899C40.9221 26.1899 41.3207 26.0007 41.6654 25.7806L43.2704 20.1303C42.9903 19.7204 42.4948
            19.4354 41.9992 19.4354ZM47.9994 31.5248H40.1788L40.739 29.5989C39.1555 30.9563 37.5504 31.9341
            34.9866 31.9341C32.3906 31.9341 30.7533 30.6713 30.7533 27.9889C30.7533 25.023 31.9059 20.6037
            33.5971 17.5114C34.8359 15.2079 37.0549 14.0086 39.6832 14.0086C41.9346 14.0086 43.6043 15.0182
            44.2614 16.2803L46.4805 7.97998H54.4518L47.9994 31.5248Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M62.6929 18.3613C61.7019 18.3613 61.0232 19.0555 
            60.5923 20.7904H63.867C63.9532 20.4433 64.0824 19.9071 64.0824 19.5903C64.0824 18.6442
            63.5224 18.3613 62.6929 18.3613ZM70.6643 24.136H59.6659C59.4505 25.0503 59.235 25.6824 59.235
            26.4077C59.235 26.7547 59.289 27.6388 60.4093 27.6388C61.5188 27.6388 62.0142 27.1964 62.4774 
            25.5244H70.2871C69.2961 30.4163 63.6193 32.0878 60.1292 32.0878C55.4002 32.0878 51.7269 
            31.3942 51.7269 26.7547C51.7269 24.1987 52.9872 19.9692 54.6569 17.7298C56.6713 15.0462 
            60.1615 14.038 63.3716 14.038C68.3159 14.038 71.526 15.0786 71.526 19.3703C71.526 20.4122 
            71.0952 22.7157 70.6643 24.136Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M78.6745 31.5248H70.7031L77.1664 
            7.97998H85.1377L78.6745 31.5248Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M94.8204 12.6828H86.8491L88.1417 7.97998H96.113L94.8204 
            12.6828ZM89.6605 31.5248H81.6892L86.4182 14.3239H94.3787L89.6605 31.5248Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.586 13.8189L109.275 17.7006H103.178L101.39 
            24.2336C101.229 24.7388 101.11 25.3695 101.11 25.7794C101.11 26.5365 101.412 26.7903 
            102.5 26.7903H104.202L103.017 31.0198H95.9178C93.5695 31.0198 92.6754 29.8515 92.6754 28.0829C92.6754 
            27.3583 92.7617 26.5682 93.0094 25.6849L97.3398 9.99933H105.311L104.255 13.8189H108.586Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M119.637 14.038L115.306 23.5694L115.435 14.038H107.367L108.908 
            31.2388C108.714 32.2458 108.39 32.8124 107.766 33.0934C107.098 33.4016 105.568 33.346 105.03 33.3155L104.922 
            33.3209L103.662 37.9947L109.101 38C112.311 38 113.884 36.3503 116.168 32.6901L127.791 14.038H119.637Z" fill="#368727"/>
            <path d="M151.161 10.98V18.33H160.611V20.52H151.161V30H148.491V9.87L148.041 
            8.76H161.451V10.98H151.161ZM164.562 11.46C163.723 11.46 163.033 10.83 163.033 9.93C163.033 9.06 163.723 
            8.4 164.562 8.4C165.373 8.4 166.063 9.06 166.063 9.93C166.063 10.83 165.343 11.46 164.562 11.46ZM163.333 
            30V15.6H165.763V30H163.333ZM179.933 26.22V19.32C178.163 17.82 176.963 17.16 174.983 17.16C172.163 17.16 
            170.272 19.68 170.272 22.77C170.272 25.92 172.193 28.47 175.073
            28.47C177.023 28.47 178.373 27.75 179.933 26.22ZM179.933 28.41H179.903C178.523 29.61 177.203 30.42 174.863 
            30.42C170.753 30.42 167.813 27.45 167.813 22.86C167.813 18.18 170.873 15.18 174.803 15.18C177.083 15.18 
            178.583 15.96 179.903 16.89H179.933V8.76H182.393V30H179.933V28.41ZM197.622 28.89C195.702 30.03 194.022 
            30.45 191.982 30.45C187.242 30.45 184.152 27.54 184.152 22.74C184.152 18.12 187.272 15.15 191.352 
            15.15C196.002 15.15 198.312 18.54 198.312 22.26C198.312 22.65 198.282 23.07 198.222 23.37H186.672C186.972 
            26.52 188.772 28.5 192.042 28.5C194.052 28.5 195.702 27.9 197.622 26.43V28.89ZM191.382 17.13C188.622 17.13 
            186.942 19.26 186.672 21.6H196.002C195.762 19.05 194.202 17.13 191.382 17.13ZM202.741 8.76V26.34C202.741 27.42 
            203.251 28.02 204.271 28.02H204.901V30H203.491C201.481 30 200.311 28.83 200.311 26.67V8.76H202.741ZM207.041 
            11.46C206.201 11.46 205.511 10.83 205.511 9.93C205.511 9.06 206.201 8.4 207.041 8.4C207.851 8.4 208.541 
            9.06 208.541 9.93C208.541 10.83 207.821 11.46 207.041 11.46ZM205.811 30V15.6H208.241V30H205.811ZM218.031 
            30C217.431 30.18 216.621 30.36 215.901 30.36C213.291 30.36 211.881 28.89 211.881 
            26.43V17.64H209.151V15.6H211.881V11.7H214.251V15.6H218.061V17.64H214.251V25.95C214.251 27.72 215.151 28.38 
            216.531 28.38C217.011 
            28.38 217.611 28.26 218.031 28.11V30ZM219.395 37.41V35.19C220.535 34.8 221.525 34.17 222.515 31.53L222.755 30.9C222.965 
            30.33 222.965 30 222.725 29.43L217.175 15.6H219.905L224.285 27.63L228.395 15.6H231.005L224.885 31.74C223.475 35.49 
            222.035 36.99 219.395 37.41ZM249.945 24.96V27.99C247.785 29.7 245.115 30.6 242.235 30.6C235.935 30.6 231.315 25.98 
            231.315 19.38C231.315 12.81 235.965 8.16 242.205 8.16C244.935 8.16 247.605 8.97 249.585 10.41V13.35C247.215 11.43 
            244.935 10.44 242.445 10.44C237.495 10.44 234.075 14.31 234.075 19.38C234.075 24.45 237.465 28.35 242.475 
            28.35C245.055 28.35 247.665 27.24 249.945 24.96ZM250.639 22.83C250.639 18.18 253.969 15.15 258.229 15.15C262.519 
            15.15 265.819 18.06 265.819 22.77C265.819 27.42 262.519 30.45 258.229 30.45C253.969 30.45 250.639 27.51 
            250.639 22.83ZM253.099 22.77C253.099 25.95 255.109 28.47 258.229 28.47C261.349 28.47 263.359 25.95 
            263.359 22.83C263.359 19.68 261.349 17.16 258.229 17.16C255.109 17.16 253.099 19.65 253.099 22.77ZM270.223 
            17.19H270.253C271.963 15.81 273.193 15.18 275.203 15.18C278.653 15.18 280.633 17.4 280.633 20.64V30H278.203V20.82C278.203 
            18.87 277.003 17.16 274.663 17.16C273.103 17.16 271.843 17.91 270.313 19.44V30H267.883V18.69C267.883 17.52 
            267.793 16.59 267.613 15.6H269.983C270.103 16.05 270.193 16.71 270.223 17.19ZM285.918 17.19H285.948C287.658 
            15.81 288.888 15.18 290.898 15.18C294.348 15.18 296.328 17.4 296.328 20.64V30H293.898V20.82C293.898 18.87 
            292.698 17.16 290.358 17.16C288.798 17.16 287.538 17.91 286.008 19.44V30H283.578V18.69C283.578 17.52 283.488 
            16.59 283.308 15.6H285.678C285.798 16.05 285.888 16.71 285.918 17.19ZM311.37 28.89C309.45 30.03 307.77 
            30.45 305.73 30.45C300.99 30.45 297.9 27.54 297.9 22.74C297.9 18.12 301.02 15.15 305.1 15.15C309.75 15.15 
            312.06 18.54 312.06 22.26C312.06 22.65 312.03 23.07 311.97 23.37H300.42C300.72 26.52 302.52 28.5 
            305.79 28.5C307.8 28.5 309.45 27.9 311.37 26.43V28.89ZM305.13 17.13C302.37 17.13 300.69 19.26 
            300.42 21.6H309.75C309.51 19.05 307.95 17.13 305.13 17.13ZM325.457 29.19C324.107 30.03 322.577 
            30.45 320.867 30.45C316.427 30.45 313.127 27.39 313.127 22.83C313.127 18.3 316.457 15.15 320.897 
            15.15C322.607 15.15 324.197 15.66 325.397 16.41V18.96C323.717 17.67 322.487 17.07 320.927 
            17.07C317.837 17.07 315.587 19.62 315.587 22.83C315.587 26.07 317.837 28.53 321.017 28.53C322.667 
            28.53 323.927 27.93 325.457 26.79V29.19ZM333.662 30C333.062 30.18 332.252 30.36 331.532 
            30.36C328.922 30.36 327.512 28.89 327.512 
            26.43V17.64H324.782V15.6H327.512V11.7H329.882V15.6H333.692V17.64H329.882V25.95C329.882 
            27.72 330.782 28.38 332.162 28.38C332.642 28.38 333.242 28.26 333.662 28.11V30Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M339.842 18.2902C338.051 18.2902 336.595 16.8171 336.595 
            14.9999C336.595 13.1829 338.051 11.7098 339.842 11.7098C341.652 11.7098 343.108 13.1829 343.108 14.9999C343.108 
            16.8171 341.652 18.2902 339.842 18.2902ZM339.842 11C337.677 11 335.887 12.7958 335.887 14.9999C335.887 17.2042 
            337.677 19 339.842 19C342.026 19 343.817 17.2042 343.817 14.9999C343.817 12.7958 342.026 11 339.842 11Z" fill="#368727"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M339.035 14.7956V13.6403H339.586C340.039 13.6307 340.55 13.6606 
            340.55 14.2125C340.55 14.7657 340.039 14.7956 339.586 14.7956H339.035ZM341.298 14.2425C341.298 13.4467 340.845 
            13 339.861 13H338.327V17.1253H339.035V15.4359H339.586L340.59 17.1253H341.377L340.314 15.3775C340.885 15.3094 
            341.298 14.9593 341.298 14.2425Z" fill="#368727"/>
        </svg>` : html`
        <svg width="410" height="37" viewBox="0 0 410 37" fill="none" xmlns="http://www.w3.org/2000/svg" alt="Fidelity Connect Logo CWV">
            <g clip-path="url(#clip0_1458_209)">
                <path d="M8.07398 29.075H0L6.46696 6H23.0167L21.4421 11.4755H12.9987L11.9166 15.4024H20.3599L18.9343 
                    20.4437H10.491L8.08046 29.075H8.07398Z" fill="black"/>
                <path d="M26.7556 29.075H18.7723L23.5027 12.2142H31.4859L26.7556 29.075ZM31.9201 10.6072H23.9433L25.2393 
                    6H33.2161L31.9201 10.6072Z" fill="black"/>
                <path d="M47.4589 29.075H39.6312L40.1885 27.1893C38.6138 28.5177 37.0003 29.4768 34.4343 29.4768C31.8682 29.4768 
                    30.1964 28.2391 30.1964 25.6082C30.1964 22.6988 31.3434 18.3702 33.0411 15.3376C34.2788 13.0826 36.5079 11.9032 
                    39.1322 11.9032C41.3872 11.9032 43.0591 12.8946 43.707 14.1323L45.9361 6H53.9194L47.4524 29.075H47.4589ZM41.4585 
                    17.2297C40.7457 17.2297 40.0329 17.45 39.6312 18.435C39.0415 19.8606 38.5166 21.2473 38.5166 22.6404C38.5166 
                    23.2884 38.9832 23.8457 39.7543 23.8457C40.3699 23.8457 40.7781 23.6578 41.1151 23.444L42.7221 17.9036C42.4435 
                    17.5019 41.951 17.2232 41.452 17.2232L41.4585 17.2297Z" fill="black"/>
                <path d="M69.9897 21.7138H58.9803C58.7664 22.608 58.5461 23.2301 58.5461 23.9429C58.5461 24.2863 58.6044 25.1482 
                    59.719 25.1482C60.8335 25.1482 61.326 24.714 61.7926 23.0746H69.6203C68.6289 27.8697 62.9395 29.5092 59.4468 
                    29.5092C54.7165 29.5092 51.0359 28.8288 51.0359 24.2799C51.0359 21.7721 52.306 17.6315 53.9713 15.4348C55.9801 
                    12.8039 59.4792 11.819 62.6933 11.819C67.6439 11.819 70.858 12.8363 70.858 17.0483C70.858 18.0721 70.4238 
                    20.3271 69.9897 21.7203V21.7138ZM62.0064 16.0568C61.015 16.0568 60.3346 16.7372 59.9004 18.435H63.1793C63.27 
                    18.098 63.3931 17.5667 63.3931 17.2621C63.3931 16.3355 62.8358 16.0568 61.9999 16.0568H62.0064Z" fill="black"/>
                <path d="M78.0053 29.075H70.022L76.489 6H84.4723L78.0053 29.075Z" fill="black"/>
                <path d="M88.5546 29.075H80.5714L85.3017 12.2142H93.285L88.5546 29.075ZM93.7191 10.6072H85.7358L87.0318 
                    6H95.0151L93.7191 10.6072Z" fill="black"/>
                <path d="M108.066 12.2142L108.759 16.018H102.648L100.854 22.4201C100.698 22.9126 100.575 23.5347 100.575 
                    23.9364C100.575 24.6816 100.886 24.9278 101.968 24.9278H103.672L102.499 29.075H95.3845C93.0323 29.075 92.138 
                    27.9281 92.138 26.1979C92.138 25.4851 92.2287 24.714 92.4815 23.8457L96.81 8.47533H104.793L103.744 
                    12.2207H108.072L108.066 12.2142Z" fill="black"/>
                <path d="M118.835 12.2142L114.507 21.5583L114.63 12.2142H106.556L108.105 29.075C107.91 30.06 107.58 30.6172 106.964 
                    30.8959C106.284 31.2004 104.754 31.1421 104.217 31.1162H104.119L102.849 35.704H108.299C111.513 35.704 113.094 
                    34.0905 115.382 30.5071L127.013 12.2272H118.848L118.835 12.2142Z" fill="black"/>
            </g>
            <path d="M352.221 27.84C351.621 28.02 350.811 28.2 350.091 28.2C347.481 28.2 346.071 26.73 346.071 
                24.27V15.48H343.341V13.44H346.071V9.54H348.441V13.44H352.251V15.48H348.441V23.79C348.441 25.56 349.341 
                26.22 350.721 26.22C351.201 26.22 351.801 26.1 352.221 25.95V27.84Z" fill="black"/>
            <path d="M342.616 27.03C341.266 27.87 339.736 28.29 338.026 28.29C333.586 28.29 330.286 25.23 330.286 20.67C330.286 
                16.14 333.616 12.99 338.056 12.99C339.766 12.99 341.356 13.5 342.556 14.25V16.8C340.876 15.51 339.646 14.91 
                338.086 14.91C334.996 14.91 332.746 17.46 332.746 20.67C332.746 23.91 334.996 26.37 338.176 26.37C339.826 26.37 
                341.086 25.77 342.616 24.63V27.03Z" fill="black"/>
            <path d="M327.129 26.73C325.209 27.87 323.529 28.29 321.489 28.29C316.749 28.29 313.659 25.38 313.659 20.58C313.659 
                15.96 316.779 12.99 320.859 12.99C325.509 12.99 327.819 16.38 327.819 20.1C327.819 20.49 327.789 20.91 327.729 
                21.21H316.179C316.479 24.36 318.279 26.34 321.549 26.34C323.559 26.34 325.209 25.74 327.129 24.27V26.73ZM320.889 
                14.97C318.129 14.97 316.449 17.1 316.179 19.44H325.509C325.269 16.89 323.709 14.97 320.889 14.97Z" fill="black"/>
            <path d="M300.277 15.03H300.307C302.017 13.65 303.247 13.02 305.257 13.02C308.707 13.02 310.687 15.24 310.687 
                18.48V27.84H308.257V18.66C308.257 16.71 307.057 15 304.717 15C303.157 15 301.897 15.75 300.367 
                17.28V27.84H297.937V16.53C297.937 15.36 297.847 14.43 297.667 13.44H300.037C300.157 13.89 300.247 14.55 
                300.277 15.03Z" fill="black"/>
            <path d="M283.182 15.03H283.212C284.922 13.65 286.152 13.02 288.162 13.02C291.612 13.02 293.592 15.24 293.592 
                18.48V27.84H291.162V18.66C291.162 16.71 289.962 15 287.622 15C286.062 15 284.802 15.75 283.272 
                17.28V27.84H280.842V16.53C280.842 15.36 280.752 14.43 280.572 13.44H282.942C283.062 13.89 283.152 14.55 283.182 
                15.03Z" fill="black"/>
            <path d="M262.198 20.67C262.198 16.02 265.528 12.99 269.788 12.99C274.078 12.99 277.378 15.9 277.378 20.61C277.378 25.26 
                274.078 28.29 269.788 28.29C265.528 28.29 262.198 25.35 262.198 20.67ZM264.658 20.61C264.658 23.79 266.668 26.31 
                269.788 26.31C272.908 26.31 274.918 23.79 274.918 20.67C274.918 17.52 272.908 15 269.788 15C266.668 15 264.658 
                17.49 264.658 20.61Z" fill="black"/>
            <path d="M260.104 22.8V25.83C257.944 27.54 255.274 28.44 252.394 28.44C246.094 28.44 241.474 23.82 241.474 17.22C241.474 
                10.65 246.124 6 252.364 6C255.094 6 257.764 6.81 259.744 8.25V11.19C257.374 9.27 255.094 8.28 252.604 8.28C247.654 
                8.28 244.234 12.15 244.234 17.22C244.234 22.29 247.624 26.19 252.634 26.19C255.214 26.19 257.824 25.08 260.104 22.8Z" 
                fill="black"/>
            <path d="M228.154 35.25V33.03C229.294 32.64 230.284 32.01 231.274 29.37L231.514 28.74C231.724 28.17 231.724 27.84 
                231.484 27.27L225.934 13.44H228.664L233.044 25.47L237.154 13.44H239.764L233.644 29.58C232.234 33.33 230.794 34.83 
                228.154 35.25Z" fill="black"/>
            <path d="M225.39 27.84C224.79 28.02 223.98 28.2 223.26 28.2C220.65 28.2 219.24 26.73 219.24 
                24.27V15.48H216.51V13.44H219.24V9.54H221.61V13.44H225.42V15.48H221.61V23.79C221.61 25.56 222.51 26.22 223.89 
                26.22C224.37 26.22 224.97 26.1 225.39 25.95V27.84Z" fill="black"/>
            <path d="M213 9.3C212.16 9.3 211.47 8.67 211.47 7.77C211.47 6.9 212.16 6.24 213 6.24C213.81 6.24 214.5 6.9 214.5 
                7.77C214.5 8.67 213.78 9.3 213 9.3ZM211.77 27.84V13.44H214.2V27.84H211.77Z" fill="black"/>
            <path d="M207.3 6.6V24.18C207.3 25.26 207.81 25.86 208.83 25.86H209.46V27.84H208.05C206.04 27.84 204.87 26.67 204.87 
                24.51V6.6H207.3Z" fill="black"/>
            <path d="M200.781 26.73C198.861 27.87 197.181 28.29 195.141 28.29C190.401 28.29 187.311 25.38 187.311 20.58C187.311 15.96 
                190.431 12.99 194.511 12.99C199.161 12.99 201.471 16.38 201.471 20.1C201.471 20.49 201.441 20.91 201.381 
                21.21H189.831C190.131 24.36 191.931 26.34 195.201 26.34C197.211 26.34 198.861 25.74 200.781 24.27V26.73ZM194.541 
                14.97C191.781 14.97 190.101 17.1 189.831 19.44H199.161C198.921 16.89 197.361 14.97 194.541 14.97Z" fill="black"/>
            <path d="M181.691 24.06V17.16C179.921 15.66 178.721 15 176.741 15C173.921 15 172.031 17.52 172.031 20.61C172.031 23.76 
                173.951 26.31 176.831 26.31C178.781 26.31 180.131 25.59 181.691 24.06ZM181.691 26.25H181.661C180.281 27.45 178.961 
                28.26 176.621 28.26C172.511 28.26 169.571 25.29 169.571 20.7C169.571 16.02 172.631 13.02 176.561 13.02C178.841 13.02 
                180.341 13.8 181.661 14.73H181.691V6.6H184.151V27.84H181.691V26.25Z" fill="black"/>
            <path d="M164.921 9.3C164.081 9.3 163.391 8.67 163.391 7.77C163.391 6.9 164.081 6.24 164.921 6.24C165.731 6.24 166.421 
                6.9 166.421 7.77C166.421 8.67 165.701 9.3 164.921 9.3ZM163.691 27.84V13.44H166.121V27.84H163.691Z" fill="black"/>
            <path d="M150.12 8.82V16.17H159.57V18.36H150.12V27.84H147.45V7.71L147 6.6H160.41V8.82H150.12Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M354 10.0147C354 7.7976 355.806 6 358.006 6C360.206 6 362 7.7976 362 
                10.0147C362 12.2317 360.206 14.0293 358.006 14.0293C355.794 14.0293 354 12.2197 354 10.0147ZM354.717 
                10.0141C354.717 11.8357 356.188 13.3097 358.006 13.3097C359.824 13.3097 361.294 11.8357 361.294 10.0141C361.294 
                8.19251 359.824 6.71847 358.006 6.71847C356.188 6.71847 354.717 8.19251 354.717 10.0141Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M358.005 8.13251C358.86 8.13251 359.261 8.52489 359.261 9.23541C359.261 
                9.8717 358.891 10.1792 358.407 10.2429L359.333 11.7912H358.643L357.758 
                10.2959H357.284V11.7912H356.667V8.13251H358.005ZM357.295 8.70734V9.7254V9.736H357.768C358.17 9.736 358.613 9.70419 
                358.613 9.21637C358.613 8.71794 358.17 8.69673 357.768 8.70734H357.295Z" fill="black"/>
            <path d="M380.848 24.312V25.928C379.696 26.84 378.272 27.32 376.736 27.32C373.376 27.32 370.912 24.856 370.912 
                21.336C370.912 17.832 373.392 15.352 376.72 15.352C378.176 15.352 379.6 15.784 380.656 16.552V18.12C379.392 17.096 
                378.176 16.568 376.848 16.568C374.208 16.568 372.384 18.632 372.384 21.336C372.384 24.04 374.192 26.12 376.864 
                26.12C378.24 26.12 379.632 25.528 380.848 24.312ZM389.224 17.672L386.392 27H385.016L381.624 15.672H383.128L385.736 
                24.952H385.784L388.52 15.672H389.992L392.792 24.952H392.84L395.448 15.672H396.888L393.48 27H392.12L389.256 
                17.672H389.224ZM398.492 15.672L401.98 25.368H402.012L405.516 15.672H407.004L402.684 27H401.244L396.972 
                15.672H398.492Z" fill="black"/>
            <defs>
                <clipPath id="clip0_1458_209">
                    <rect width="127" height="29.704" fill="white" transform="translate(0 6)"/>
                </clipPath>
            </defs>
        </svg>`;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }
}

