/*
 * Copyright 2021-2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import { LitElement, html, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { NavigationDataService } from '../app/services/navbar-data.service';
import { PSPUBLIC } from '../app/constants/navbar.constants';

// child component imports
import './components/utility-navbar/component';
import './components/psw-logo/component';
import './components/FC/connect-logo/component';
import './components/recently-viewed-participants/component';
import './components/psw-cwv-logo/component';
import './components/FC/fc-navbar/component';
import { PlanAdvisorExperience } from './constants/navbar.constants';
import './components/FC/autocomplete-dropdown/component';

/**
 * This is a feature of TypeScript and not specific to LitElement.
 * This declaration helps TypeScript to provide strong typing when interacting with DOM APIs.
 * The JavaScript DOM API of course does not know or care about types,
 * but TypeScript does. With this mechanism you can add the type of your custom elements to the DOM APIs
 */
declare global {
    interface HTMLElementTagNameMap {
        'psw-navbar-component': AppRoot;
    }
}

/**
 * AppRoot component that extends the LitElement base class.
 * @memberOf AppRoot
 */
@customElement('psw-navbar-component')
export class AppRoot extends LitElement {

    /**
     * Boolean representing the Modal open/close for the "shouldOpenModal"
     * @memberOf AppRoot
     */
    @property({ attribute: false })
    private shouldOpenModal: boolean = false;

    @property({ type: String })
    private navbarData: string = '';

    @property({ type: String })
    private pageId!: string;

    @property({ type: String })
    private realm: string = '';

    @property({ type: String })
    private asyncCallForData!: string;

    /**
     * Boolean representing the spinner for the "loading"
     * @memberOf AppRoot
     */
    @state()
    private loading: boolean = true;

    private fcPageId: string = '';

    private clientId = '';

    private experience = '';

    constructor() {
        super();
    }

    /**
     * CreateRenderRoot method : Removes the shadow dom
     * @memberOf AppRoot
     */
    public createRenderRoot(): AppRoot {
        return this;
    }

    /**
     * connectedCallback method is a lifecycle callback in the custom elements API.
     * connectedCallback fires each time a custom element is appended
     * into a document-connected element
     * @memberOf AppRoot
     */
    public connectedCallback(): void {
        super.connectedCallback();
        // wait 0 second then render
        if (this.asyncCallForData === 'yes') {
            this.loading = true;
            NavigationDataService.getNavbarData(this.pageId, this.realm)
                .then((data) => {
                    this.navbarData = data;
                    this.loading = false;
                    this.extendSession();
                    this.addPageServicesScripts();
                });
        } else {
            this.loading = false;
            const navbarDataObject = JSON.parse(this.navbarData);
            this.experience = navbarDataObject.experience || '';
            if(this.experience.indexOf(PSPUBLIC) === -1) {
                this.extendSession();
            }
        }
    }

    /**
     * Toggle Modal change event , Modal opened or closed
     * @param event
     */
    public onModalChange(event: CustomEvent) {
        switch (event.detail.state) {
        case 'closed':
            this.shouldOpenModal = false;
            break;
        case 'opened':
            this.shouldOpenModal = true;
            break;
        }
    }

    public extendSession(): void {
        const navbarDataObject = JSON.parse(this.navbarData);
        const user = navbarDataObject.user;
        this.clientId = navbarDataObject.client;
        this.experience = navbarDataObject.experience || '';
        const body = document.getElementsByTagName('BODY')[0];
        const script = document.createElement('script');
        script.id = 'timeout';
        script.setAttribute('user',user);
        if(this.experience.toLocaleLowerCase().startsWith(PlanAdvisorExperience)) {
            script.setAttribute('plan_advisor_realm','/planadvisor');
        }
        script.src = '/static/psw/cacheable/psw3_0/common/js/extend_session.js';
        body.appendChild(script);
        const style = document.createElement('link');
        style.href = '/static/psw/cacheable/psw3_0/common/css/extend_session.css';
        style.rel = 'stylesheet';
        style.type = 'text/css';
        body.appendChild(style);
    }

    /**
     *  Adding page services scripts to body
     */
    public addPageServicesScripts(): void  {
        const navbarDataObject = JSON.parse(this.navbarData);
        const pageServices = navbarDataObject?.pageServices?.pageFeatures;
        const pageFeatures = navbarDataObject?.pageFeatures;
        const body = document.getElementsByTagName('BODY')[0];
        const div = document.createElement('div');
        pageServices?.forEach((feature: { featureIncludes: any[] }) => {
            feature.featureIncludes.forEach((feature: { rel: any; href: string }, index: any) => {
                const scriptEl = document.createElement('script');
                const style = document.createElement('link');
                if(feature.rel != null) {
                    switch (feature.rel) {
                    case 'JS':
                        scriptEl.src = feature.href;
                        body.appendChild(scriptEl);
                        break;
                    case 'CSS':
                        style.href = feature.href;
                        style.rel = 'stylesheet';
                        style.type = 'text/css';
                        body.appendChild(style);
                        break;
                    default:
                        break;
                    }
                }
            });
        });
        div.innerHTML = pageFeatures;
        body.appendChild(div);
    }

    /**
     *  Open Event - Modal Toggle event
     */
    public openModal() {
        this.shouldOpenModal = true;
    }

    /**
     * The render function defines a template literal and
     * makes use of the spinner element using this below template as if it were part of the HTML
     * @memberOf AppRoot
     */
    public render(): TemplateResult {

        if(this.realm.toLocaleLowerCase().startsWith(PlanAdvisorExperience)) {
            return html`
               ${!this.loading ? this.renderContent() :
                    html`<div class = 'advisorSpinner'><psw-navbar-pvd-spinner></psw-navbar-pvd-spinner></div>`}`;
        } else {
            return html`
               ${!this.loading ? this.renderContent() : html`<psw-navbar-pvd-spinner></psw-navbar-pvd-spinner>`}`;
        }
    }

    /**
     * This function is responsible for emit custom events from this element
     * @param name {string} name - name of the page
     * @param data
     * @memberOf AppRoot
     */
    public _dispatchEvent(name: string, data?: unknown) {
        const event = new CustomEvent(`psw-navbar-component:${name}`, {
            detail: data
        });
        this.dispatchEvent(event);
    }

    /**
     * The renderContent function defines a template literal and
     * makes use of the utility navbar element using this below template as if it were part of the HTML
     * @memberOf AppRoot
     */
    private renderContent() {

        if(this.navbarData) {
            if(this.experience.toLocaleLowerCase().startsWith(PlanAdvisorExperience)) {
                return html `
                    <div id="tocover"></div>
                    <div id="extsessdlg" role="alertdialog" aria-describedby="warningcontent"></div>
                    <fc-navbar navbarData=${this.navbarData} clientId=${this.clientId}></fc-navbar>
                `;
            } else {
                return html `
                <div>
                    <div id="tocover"></div>
                    <div id="extsessdlg" role="alertdialog" aria-describedby="warningcontent"></div>
                    <psw-navbar-utility-nav navbarData=${this.navbarData}></psw-navbar-utility-nav>
                    <psw-navbar-pvd-modal pvd-open="${this.shouldOpenModal}" @pvd-modal-change="${this.onModalChange}">
                    </psw-navbar-pvd-modal>
                </div>
                `;
            }
        }
    }
}
