import { LitElement, html, TemplateResult, nothing } from 'lit';
import { customElement, property, query, queryAsync } from 'lit/decorators.js';
import { injectCss } from '@fmr-ap123285/dp-microfrontend-utils';

import ComponentStyles from './component.scss';
import { Client } from '../../../models/content';
import { TaggingService } from '../../../services/tagging.service';

declare global {
    interface HTMLElementTagNameMap {
        'autocomplete-dropdown': AutocompleteDropdown;
    }
}

@injectCss(ComponentStyles)
@customElement('autocomplete-dropdown')
export class AutocompleteDropdown extends LitElement {
    @property({ type: Array })
    public clientList: Client[] = [];

    @property({ type: Array })
    public filteredList: Client[] = [];

    @property({ type:Boolean })
    public toggleDropdown: boolean = false;

    @queryAsync('#hiddenInput')
    public hiddenInputElement?: HTMLInputElement;

    @query('#autocomplete-input')
    public autoCompleteInputElement?: HTMLInputElement;

    @query('#advisorClientSelectForm')
    public advisorClientSelectFormElement?: HTMLFormElement;

    @property({ type: String })
    public userType: string | undefined = '';

    @property({ type: String })
    private client: string = '';

    public selectedValue: string = '';

    private hiddenInputValue: string = '';

    public render(): TemplateResult {
        return html`
                <div id="autocomplete-input-root" class="autocomplete-input-root"  @click=${this.toggle}>

                    <psw-navbar-pvd-icon pvd-name="action__caret-down" class="dropdown_caret_icon" id="expand-collapse-icon"
                        pvd-a11y-label="expand dropdown" @click=${this.focusElement}>
                    </psw-navbar-pvd-icon>
                    <input type="text" id="autocomplete-input" aria-label="Select or enter a client"
                        class="autocomplete-input" name="autocomplete-input" minlength="2" .value="${this.selectedValue}"
                        @keyup=${this.filterList}
                        placeholder="Select or enter a client"  onfocus="this.select()"/>


                </div>
                ${this.toggleDropdown ? html`
                    <div class="dropdown-root" id="dropdown-root">
                        <ul class="dropdown-options" id="dropdown-options">
                            ${this.filteredList?.map((item, i) => {
                                return html`
                                <li class="client-selector-list">
                                    <button class="dropdownElement" .value="${item.clientId}"
                                        @click=${this.selectClient}>${item.clientName}
                                    </button>
                                </li>
                        `;
                        })}

                        </ul>
                    </div>
                `:nothing}
                <form id="advisorClientSelectForm" name="advisorClientSelectForm" action="/planadvisor/pswmain/AdvisorSetClient.psw"
                    method="POST">
                        <input type="hidden" id="hiddenInput" name="clientId" value="${this.hiddenInputValue}" onfocus="this.select()"/>
                </form>
        `;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }

    public connectedCallback() {
        super.connectedCallback();
        this.filteredList = this.clientList;
        if(this.client) {
            const clientTemp = this.clientList.find((item)=> item.clientId === this.client);
            if(clientTemp) {
                this.selectedValue = clientTemp.clientName;
            }
        }

        document.addEventListener('click', (event) => {
            if (!event.composedPath().includes(this)) {
                this.toggleDropdown = false;
            } else {
                this.toggleDropdown = true;
                this.filterList(event);
            }
        });

        (document).addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                this.toggleDropdown = false;
            }
        });

        (document).addEventListener('focusout', (event) => {
            if (event.composedPath().includes(this)) {
                this.autoCompleteInputElement!.value = this.selectedValue;
            }
        });

    }

    public toggle() {
        this.toggleDropdown = true;
    }

    public focusElement() {
        this.autoCompleteInputElement?.focus();
    }

    public filterList(e:any) {
        this.filteredList = this.clientList;
        this.filteredList = this.clientList.filter((item) => item.clientName.toLowerCase().includes(e.target.value.toLowerCase()));
        if (this.filteredList.length === 0)  {
            this.toggleDropdown = false;
        } else {
            this.toggleDropdown = true;
        }
    }

    public async selectClient(e:any) {
        this.toggleDropdown = false;
        const clientTemp = this.clientList.find((item)=> item.clientId === e.target.value);
        if(clientTemp) {
            let bobContext = true;
            if(this.selectedValue) {
                bobContext = false;
            }
            this.selectedValue = clientTemp.clientName;
            TaggingService.submitAnalyticsDropdown(bobContext, this.userType, this.selectedValue);
        }
        if(e.target.value) {
            this.hiddenInputValue=e.target.value;
            await this.hiddenInputElement?.value;
            this.advisorClientSelectFormElement?.submit();
        }
    }
}
