/*
 * Copyright 2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 * @author Venkat Guntaka(a672240)
 */
import { ExperienceApiHeaderStatusValidity } from '@fmr-ap123285/dp-microfrontend-utils';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ajax, AjaxConfig, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { Environment } from '../../environments/environment';
import { UpdatePreferencesResponse, TxnToken, ParticipantSearchData } from '../models/content';
export class ParticipantSearchService {

    public static getCSRFToken(): Observable<TxnToken> {
        return ajax<TxnToken>({
            url: Environment.txnTokenUrl,
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json; charset=UTF-8'
            }
        } as AjaxConfig).pipe(
            // checks ajax responses for `fid-ws-http-status` as per experience api standards
            ExperienceApiHeaderStatusValidity(),
            map((ajaxResponse: AjaxResponse<TxnToken>) => {
                return ajaxResponse.response;
            }), catchError((ajaxError: AjaxError) => {
                // eslint-disable-next-line
                console.log(ajaxError.status, ajaxError.message);
                throw new Error('Get TxnToken Failed');
            })
        );
    }

    public static updatePreferences(searchType: string): Observable<UpdatePreferencesResponse> {

        return ajax<UpdatePreferencesResponse>({
            url: '/plansponsor/participantsearch/api/preferences/'+searchType,
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        } as AjaxConfig).pipe(
            ExperienceApiHeaderStatusValidity(),
            map((ajaxResponse: AjaxResponse<UpdatePreferencesResponse>) => {
                return ajaxResponse.response;
            }), catchError((ajaxError: AjaxError) => {
                // eslint-disable-next-line
                console.log(ajaxError.status, ajaxError.message);
                throw new Error('Update Preferences Failed');
            })
        );
    }

    public static getParticipantSearchOption(): Observable<ParticipantSearchData> {
        return ajax<ParticipantSearchData>({
            url: Environment.participantSearchOptionsDataUrl,
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                txntoken: this.getCSRFToken()
            }
        } as AjaxConfig).pipe(
            // checks ajax responses for `fid-ws-http-status` as per experience api standards
            ExperienceApiHeaderStatusValidity(),
            map((ajaxResponse: AjaxResponse<ParticipantSearchData>) => {
                return ajaxResponse.response;
            }), catchError((ajaxError: AjaxError) => {
                // eslint-disable-next-line
                console.log(ajaxError.status, ajaxError.message);
                throw new Error('Participant Search Data Service Failed');
            })
        );
    }


}
