/*
 * Copyright 2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 * @author Azra Shaikh(a639961)
 */
import { LitElement, html, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { RecentlyViewedParticipantsService } from '../../services/recently-viewed-participants.service';
import { finalize } from 'rxjs';
import { RecentlyViewedParticipants, ViewedParticipants, ParticipantChosenResponse } from '../../models/content';
import { RecentlyViewedParticipantServiceError,
    NoRecentlyViewedParticipantMessage } from '../../constants/errors.constants';
const SUBMIT_FAILED_EVENT = 'failed';
const SUBMIT_SUCCESS_EVENT = 'submitted';

declare global {
    interface HTMLElementTagNameMap {
        'psw-recently-viewed-participants': RecentlyViewedParticipantsComponent;
    }
}

@customElement('psw-recently-viewed-participants')
export class RecentlyViewedParticipantsComponent extends LitElement {
    static renderedHtml: TemplateResult | undefined;
    /**
    * Boolean representing the spinner for the "loading"
    * @memberOf RecentlyViewedParticipantsComponent
    */
    @state()
    public loading: boolean = true;

    public rvParticipantsAvailable: boolean = false;

    public viewedParticipantsError: boolean = false;

    public viewedParticipants!: ViewedParticipants[];

    public errorMessage!: string;

    public infoMessage!: string;

    private isInitialized: boolean = false;

    public connectedCallback(): void {
        super.connectedCallback();

        if (this.isInitialized === false) {
            this.isInitialized = true;
            this.getRecentlyViewedParticipantsData();
        }
    }

    public getRecentlyViewedParticipantsData(): void {
        RecentlyViewedParticipantsService.getViewedParticipants()
            .pipe(finalize(() => {
                this._dispatchEvent('load-complete', { message: 'yayy!' });
            }))
            .subscribe(
                {
                    next: (data: RecentlyViewedParticipants) => {
                        if (data === null) {
                            this.getRecentlyViewedParticipantsErrorMessage();
                        } else {
                            if (data.viewedparticipants !== undefined) {
                                this.viewedParticipants = data.viewedparticipants;
                            }
                            const partialParticipants = data.partialParticipants;
                            if (this.viewedParticipants !== undefined && this.viewedParticipants.length > 0) {
                                this.rvParticipantsAvailable = true;
                                if (partialParticipants) {
                                    // eslint-disable-next-line
                                    console.log(RecentlyViewedParticipantServiceError);
                                    this.getRecentlyViewedParticipantsErrorMessage();
                                }
                            } else {
                                if (data.partialParticipants === undefined) {
                                    // eslint-disable-next-line
                                    console.log(data);
                                    this.getRecentlyViewedParticipantsErrorMessage();
                                } else {
                                    this.rvParticipantsAvailable = false;
                                    this.infoMessage = NoRecentlyViewedParticipantMessage;
                                    // eslint-disable-next-line
                                    console.log(NoRecentlyViewedParticipantMessage);
                                }
                            }
                        }
                    }, error: (err: Error) => {
                        // eslint-disable-next-line
                        console.log(err);
                        this.getRecentlyViewedParticipantsErrorMessage();
                    }, complete: () => {
                        this.loading = false;
                    }
                }
            );
    }

    public participantSelected(wid: string, destination: string): void {
        const formData: FormData = new FormData();
        formData.append('whatToView', destination);
        formData.append('wid', wid);

        RecentlyViewedParticipantsService.chooseParticipant(formData)
            .subscribe(
                {
                    next: (response: ParticipantChosenResponse) => {
                        // eslint-disable-next-line
                        (<any>window).location.href = encodeURI(response.href);
                        this._dispatchEvent(SUBMIT_SUCCESS_EVENT, wid);
                    }, error: (err: Error) => {
                        // eslint-disable-next-line
                        console.log(err);
                        this._dispatchEvent(SUBMIT_FAILED_EVENT, wid);
                    }
                }
            );
    }

    public renderParticipantList(): boolean {
        if (!this.loading && this.rvParticipantsAvailable && !this.viewedParticipantsError) {
            return true;
        } else {
            return false;
        }
    }

    public infoMessageDisplay(): boolean {
        if (!this.loading && this.viewedParticipants === undefined && !this.viewedParticipantsError) {
            return true;
        }
        return false;
    }

    public render(): TemplateResult {
        RecentlyViewedParticipantsComponent.renderedHtml = html`
            <h3 class="main-nav__submenu-title">Recently viewed</h3>
            
            ${this.loading ? html`<psw-navbar-pvd-spinner></psw-navbar-pvd-spinner>` :
                html``}

            <ul class="main-nav__submenu">
                ${this.renderParticipantList() ? html`
                    ${this.viewedParticipants?.map((participant: ViewedParticipants) => html `
                        <li>
                            <a href="javascript:void(0)" @click=${() =>
                                this.participantSelected(participant.wid, '/plansponsor/participant/ParticipantLanding.psw')}
                                class="main-nav__link" tabindex="0">
                                ${participant.firstname?.substring(0,1)}${participant.firstname?.substring(1).toLowerCase()}
                                ${participant.lastname?.substring(0,1)}${participant.lastname?.substring(1).toLowerCase()}
                            </a>
                        </li>`
                    )}
                ` : html``}

                ${this.infoMessageDisplay() ?
                    html`<li><psw-navbar-pvd-inline-alert pvd-variant="info" tabindex="0">${this.infoMessage}
                    </psw-navbar-pvd-inline-alert></li>`
                    : html``}
                
                ${this.viewedParticipantsError ?
                    html`<li><psw-navbar-pvd-inline-alert tabindex="0">${this.errorMessage}</psw-navbar-pvd-inline-alert></li>` :
                    html``
                }
               
            </ul>
        `;
        return RecentlyViewedParticipantsComponent.renderedHtml;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }
    /* way to emit custom events from this element */
    public _dispatchEvent(name:string, data: unknown) {
        const event = new CustomEvent(name, {
            detail: data
        });
        this.dispatchEvent(event);
    }

    private getRecentlyViewedParticipantsErrorMessage(): void {
        this.rvParticipantsAvailable = false;
        this.errorMessage = RecentlyViewedParticipantServiceError;
        this.viewedParticipantsError = true;
        this.loading = false;
    }
}
