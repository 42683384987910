import { ExperienceApiHeaderStatusValidity } from '@fmr-ap123285/dp-microfrontend-utils';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ajax, AjaxConfig, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { Environment } from '../../environments/environment';
import { RecentlyViewedParticipants, ParticipantChosenResponse } from '../models/content';

export class RecentlyViewedParticipantsService {

    public static getViewedParticipants(): Observable<RecentlyViewedParticipants> {
        return ajax<RecentlyViewedParticipants>({
            url: Environment.recentlyViwewedParticipantsDataUrl,
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json; charset=UTF-8',
                txntoken: this.getCSRFToken()
            }
        } as AjaxConfig).pipe(
            // checks ajax responses for `fid-ws-http-status` as per experience api standards
            ExperienceApiHeaderStatusValidity(),
            map((ajaxResponse: AjaxResponse<RecentlyViewedParticipants>) => {
                return ajaxResponse.response;
            }), catchError((ajaxError: AjaxError) => {
                // eslint-disable-next-line
                console.log(ajaxError.status, ajaxError.message);
                throw new Error('Recently Viewed Participants Data Service Failed');
            })
        );
    }

    // eslint-disable-next-line
    static getCSRFToken(): any {
        // eslint-disable-next-line
        return (<any>window).txntoken;
    }

    public static chooseParticipant(form: FormData): Observable<ParticipantChosenResponse> {
        return ajax<ParticipantChosenResponse>({
            url: Environment.participantChosenUrl,
            method: 'POST',
            body: form,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                txntoken: this.getCSRFToken()
            }
        } as AjaxConfig).pipe(
            ExperienceApiHeaderStatusValidity(),
            map((ajaxResponse: AjaxResponse<ParticipantChosenResponse>) => {
                return ajaxResponse.response;
            }), catchError((ajaxError: AjaxError) => {
                // eslint-disable-next-line
                console.log(ajaxError.status, ajaxError.message);
                throw new Error('Participant Chosen Service Failed');
            })
        );
    }

}
