/*
 * Copyright 2021-2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import {
    LitElement,
    html,
    TemplateResult,
    nothing
} from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { injectCss } from '@fmr-ap123285/dp-microfrontend-utils';
import ComponentStyles from './component.scss';
import { Client, MenuGroups } from '../../../models/content';
import { TaggingService } from '../../../services/tagging.service';

declare global {
    interface HTMLElementTagNameMap {
        'connect-navbar-header-nav': ConnectHeaderNavbar;
    }
}

/**
 * ConnectHeaderNavbar component that extends the LitElement base class.
 * @memberOf ConnectHeaderNavbar
 */
@injectCss(ComponentStyles)
@customElement('connect-navbar-header-nav')

export class ConnectHeaderNavbar extends LitElement {

    @property({ type: Array })
    public navTabs: MenuGroups[] | undefined = undefined;

    @property({ type: Array })
    public clientList: Client[] = [];

    @query('#client_selector')
    public clientDropdownElement?: HTMLSelectElement;

    @property({ type: Boolean })
    public bobContext: boolean = true;

    @property({ type: String })
    public userType: string | undefined = '';

    @property({ type: Array })
    private secondaryNav: boolean[] = [];

    @property({ type: String })
    private pageId: string = '';

    @property({ type: String })
    private pageTitle: string = '';

    @property({ type: String })
    private client: string = '';

    private activeTab: number = 0;

    private hasActiveLink: boolean = false;

    private showClientDropdown: boolean = false;

    private pageBreadcrumb: string[] = [];

    private activeSecondaryNav: string = '';

    /**
     * CreateRenderRoot method : Removes the shadow dom
     * @memberOf ConnectHeaderNavbar
     */
    public createRenderRoot() {
        return this;
    }

    public connectedCallback() {
        super.connectedCallback();
        for (const i in this.navTabs!) {
            this.navTabs![i].menuSubGroups[0].pageList.map((navLink, index) => {
                this.pageBreadcrumb = navLink.id.toLocaleLowerCase().split('|');

                if(this.pageBreadcrumb.includes(this.pageId.toLocaleLowerCase()) && !this.hasActiveLink) {
                    this.secondaryNav[i] = true;
                    this.hasActiveLink = true;
                    this.activeSecondaryNav = navLink.id.toLocaleLowerCase();
                }
            });
        }

        if(this.clientList.length>1) {
            this.clientList = this.clientList.map((item)=> {
                item.clientName = item.clientName.trim();
                return item;
            });

            this.clientList.sort((a,b)=> a.clientName.localeCompare(b.clientName));
            this.showClientDropdown = true;
        }
    }

    /**
     * The render function defines a template literal and
     * makes use of the header navbar element using this below template as if it were part of the HTML
     * @memberOf ConnectHeaderNavbar
     */
    /* eslint-disable max-len*/
    /* eslint-disable lit/binding-positions*/
    public render(): TemplateResult {
        return html`
        <div class="header-nav">
            <div class="header">
                <psw-navbar-pvd-title>
                    <h1 slot="heading" class="pvd-title__heading pvd-title__heading--level-1" id="title">
                    ${this.pageTitle}
                    </h1>
                </psw-navbar-pvd-title>

                ${this.showClientDropdown ? html`
                <autocomplete-dropdown .clientList=${this.clientList} .client=${this.client} .userType=${this.userType}></autocomplete-dropdown>`:nothing}

            </div>
            <div class = "fc-nav-tabs">
                <ul class="tabs-list">
                    ${this.navTabs?.map((item, i) => {
                        return item.menuSubGroups.map((tab, index) => html`
                        <li class="${this.secondaryNav[i] ? 'tab-active' : 'tab'}">
                            <a id="${tab.pageList[0].label}"
                                href=${tab.pageList.at(0)?.link.target}
                                aria-label=${tab.pageList.at(0)?.popupIcon === 'NEW_WINDOW' ?
                                    tab.pageList[0].label + ' ' + (i + 1) + ' of ' + this.navTabs?.length + '. Opens in new Window':
                                    tab.pageList[0].label + ' ' + (i + 1) + ' of ' + this.navTabs?.length
                                    }
                                target=${tab.pageList.at(0)?.targetType}
                                @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                                ${tab.pageList[0].label}
                                ${tab.pageList.at(0)?.popupIcon === 'NEW_WINDOW' ? html `<psw-navbar-pvd-icon class="newWindowIcon" pvd-name="action__open-link" pvd-size="small"></psw-navbar-pvd-icon>` : nothing}
                            </a>
                        </li>
                    `);

                    })}

                </ul>
                ${this.navTabs?.map((item, i) => {
                    {return this.secondaryNav[i] && item.menuSubGroups.at(this.activeTab)!.pageList.length > 1 ? html `
                    <ul class="tabs-list-secondary">
                        ${item.menuSubGroups.at(this.activeTab)?.pageList.map((page, index) => {
                            // will not yield a secondary menu if count of pageList entries is 1.
                        return  index > 0 ? html`
                            <li class="${this.activeSecondaryNav === page.id.toLocaleLowerCase()
                                            ? 'tab-secondary-active' : 'tab-secondary'}">
                                <a id="${item.menuSubGroups.at(this.activeTab)?.pageList[0].label}:${page.label}"
                                href=${page.link.target}
                                aria-label=${page.popupIcon === 'NEW_WINDOW' ?
                                    page.label + ' ' + (index) + ' of ' + (Number(item.menuSubGroups.at(this.activeTab)?.pageList.length)-1) + '. Opens in new Window' :
                                    page.label + ' ' + (index) + ' of ' + (Number(item.menuSubGroups.at(this.activeTab)?.pageList.length)-1)
                                    }
                                target=${page.targetType}
                                @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                                ${page.label}
                                ${page.popupIcon === 'NEW_WINDOW' ? html `<psw-navbar-pvd-icon class="newWindowIcon" pvd-name="action__open-link" pvd-size="extra-small"></psw-navbar-pvd-icon>` : nothing}
                                </a>
                            </li>
                        ` : nothing;
                        }
                    )}
                    </ul>
                `: nothing;}
                    })}
            </div>
        </div>
        `;
    }

}
