/*
 * Copyright 2022, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import { Environment } from '../../environments/environment';

export class NavigationDataService {
    // eslint-disable-next-line
    public static getNavbarData(pageId: any, realm: string): Promise<any> {
        return fetch(Environment.getNavigationDataUrl(pageId, realm))
            .then((res)=> {
                if(res.status < 400) {
                    return res.text();
                }
                return res.text().then((text) => {
                    throw new Error(text);
                });
            });
    }

}
