/*
 * Copyright 2021-2024, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import {
    LitElement,
    html,
    TemplateResult,
    nothing
} from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { injectCss } from '@fmr-ap123285/dp-microfrontend-utils';
import ComponentStyles from './component.scss';
import { UtilityNavbar } from '../../../models/content';
import { TaggingService } from '../../../services/tagging.service';

/**
 * This is a feature of TypeScript and not specific to LitElement.
 * This declaration helps TypeScript to provide strong typing when interacting with DOM APIs.
 * The JavaScript DOM API of course does not know or care about types,
 * but TypeScript does. With this mechanism you can add the type of your custom elements to the DOM APIs
 */

/**
 * ConnectUtilityNavbar component that extends the LitElement base class.
 * @memberOf ConnectUtilityNavbar
 */
@injectCss(ComponentStyles)
@customElement('connect-navbar-utility-nav')

export class ConnectUtilityNavbar extends LitElement {

    @property({ type: Object })
    public utilityNav: UtilityNavbar | null = null;

    @property({ type: Boolean })
    public simple: boolean = false;

    @property({ type: Boolean })
    public bobContext: boolean = true;

    @property({ type: String })
    public userType: string | undefined = '';

    @property({ type: String })
    public experience: string | undefined = '';

    /**
     * CreateRenderRoot method : Removes the shadow dom
     * @memberOf ConnectUtilityNavbar
     */
    public createRenderRoot() {
        return this;
    }

    public connectedCallback() {
        super.connectedCallback();
    }

    /**
     * The render function defines a template literal and
     * makes use of the utility navbar element using this below template as if it were part of the HTML
     * @memberOf ConnectUtilityNavbar
     */

    /* eslint-disable max-len*/
    public render(): TemplateResult {
        return html`
        <div class="utility-nav ${this.experience!.toLocaleLowerCase().indexOf('_cwv') >= 0 ? 'black-bar' : 'green-bar'}">
            <div class="wrapper">
                <a id="logo" class="logo" href=${this.utilityNav?.logo.target} aria-label="Fidelity Connect" @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                    <connect-logo .experience=${this.experience}></connect-logo>
                </a>
                <div class=items>
                        ${this.utilityNav?.utilities?.map((utility) => {
                        switch(utility.utility) {
                            case 'Set Personal Notification':
                                return html `
                                <div title="Notifications">
                                   <a id="${utility.page.label}" class="bell" href=${utility.page.link.target} target=${utility.page.targetType} aria-label=${utility.page.label} @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                       <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5225 18.0879V11.5879C20.5186
                                       8.03818 18.3092 4.86465 14.9815 3.62892C14.7451 2.17729 13.4912 1.11108 12.0205 1.11108C10.5497 1.11108 9.29584 2.17729 9.05946 3.62892C5.73202 4.86428 3.52371 8.03856 3.52246 11.5879V18.0879C3.52246 19.7448 2.17932 21.0879 0.522461 21.0879H23.5225C21.8656 21.0879 20.5225 19.7448 20.5225 18.0879Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                       <path d="M15.0225 21.0879C15.0225 22.7447 13.6793 24.0879 12.0225 24.0879C10.3656
                                       24.0879 9.02246 22.7447 9.02246 21.0879" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                       </svg>
                                   </a>
                                </div>`;
                            case 'User Profile':
                                return html `
                                <div title="Profile">
                                    <a id="${utility.page.label}" class="icon" href=${utility.page.link.target} target=${utility.page.targetType} aria-label=${utility.page.label}  @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <circle cx="13.795" cy="13.2444" r="12.5405" stroke="black" stroke-width="0.928928"/>
                                        <path d="M21.332 23.2854C21.3272 19.2954 17.9815 16.2676 13.8598 16.263C9.73818 16.2676 6.30677 19.2954 6.302 23.2854M13.8598 6.75244C16.2472 6.75244 18.1825 8.62598 18.1825 10.9371C18.1825 13.2482 16.2472 15.1218 13.8598 15.1218C11.4725 15.1218 9.53713 13.2482 9.53713 10.9371C9.53713 8.62598 11.4725 6.75244 13.8598 6.75244Z" stroke="black" stroke-width="0.928928" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>`;
                            case 'Logout':
                                return html `
                                    <div class="vertical_divider"></div>
                                    <div title="Log out" class="logout_wrapper">
                                        <psw-navbar-pvd-link pvd-id="${utility.page.label}" id="FCLogoutLink" pvd-class="logout" pvd-href=${utility.page.link.target} pvd-target=${utility.page.targetType} pvd-aria-label=${utility.page.label}  @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                                            ${utility.page.label}
                                        </psw-navbar-pvd-link>
                                    </div>`;
                            case 'Login':
                                return html `
                                    <div title="Log in" class="login_wrapper">
                                        <psw-navbar-pvd-link pvd-id="${utility.page.label}" pvd-class="login" pvd-href=${utility.page.link.target} pvd-target=${utility.page.targetType} pvd-aria-label=${utility.page.label}  @click="${(event:Event) => TaggingService.submitAnalytics(this.bobContext, this.userType, event)}">
                                            ${utility.page.label}
                                        </psw-navbar-pvd-link>
                                    </div>`;
                            default:
                                return nothing;
                        }
                    })}
                </div>
            </div>
        </div>
        `;
    }
}
