import { LitElement, html, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';

declare global {
    interface HTMLElementTagNameMap {
        'psw-cwv-logo': PswCwvLogo;
    }
}

@customElement('psw-cwv-logo')
export class PswCwvLogo extends LitElement {
    public render(): TemplateResult {
        return html`
            <svg xmlns="http://www.w3.org/2000/svg" width="268" height="38" viewBox="0 0 268 38" fill="none">
            <g clip-path="url(#clip0_1379_1913)">
            <path d="M8.07398 29.575H0L6.46696 6.5H23.0167L21.4421 11.9755H12.9987L11.9166 15.9024H20.3599L18.9343 
            20.9437H10.491L8.08046 29.575H8.07398Z" fill="black"/>
            <path d="M26.7556 29.575H18.7723L23.5027 12.7142H31.4859L26.7556 29.575ZM31.9201 11.1072H23.9433L25.2393 
            6.5H33.2161L31.9201 11.1072Z" fill="black"/>
            <path d="M47.4589 29.575H39.6312L40.1885 27.6893C38.6138 29.0177 37.0003 29.9768 34.4343 29.9768C31.8682 
            29.9768 30.1964 28.7391 30.1964 26.1082C30.1964 23.1988 31.3434 18.8702 33.0411 15.8376C34.2788 13.5826 
            36.5079 12.4032 39.1322 12.4032C41.3872 12.4032 43.0591 13.3946 43.707 14.6323L45.9361 6.5H53.9194L47.4524 
            29.575H47.4589ZM41.4585 17.7297C40.7457 17.7297 40.0329 17.95 39.6312 18.935C39.0415 20.3606 38.5166 21.7473 
            38.5166 23.1404C38.5166 23.7884 38.9832 24.3457 39.7543 24.3457C40.3699 24.3457 40.7781 24.1578 41.1151 
            23.944L42.7221 18.4036C42.4435 18.0019 41.951 17.7232 41.452 17.7232L41.4585 17.7297Z" fill="black"/>
            <path d="M69.9896 22.2138H58.9802C58.7664 23.108 58.5461 23.7301 58.5461 24.4429C58.5461 24.7863 58.6044 
            25.6482 59.719 25.6482C60.8335 25.6482 61.326 25.214 61.7925 23.5746H69.6203C68.6288 28.3697 62.9395 30.0092 
            59.4468 30.0092C54.7165 30.0092 51.0359 29.3288 51.0359 24.7799C51.0359 22.2721 52.3059 18.1315 53.9713 
            15.9348C55.98 13.3039 59.4792 12.319 62.6932 12.319C67.6439 12.319 70.8579 13.3363 70.8579 17.5483C70.8579 
            18.5721 70.4238 20.8271 69.9896 22.2203V22.2138ZM62.0064 16.5568C61.0149 16.5568 60.3345 17.2372 59.9004 
            18.935H63.1792C63.27 18.598 63.3931 18.0667 63.3931 17.7621C63.3931 16.8355 62.8358 16.5568 61.9999 
            16.5568H62.0064Z" fill="black"/>
            <path d="M78.0053 29.575H70.022L76.489 6.5H84.4723L78.0053 29.575Z" fill="black"/>
            <path d="M88.5546 29.575H80.5714L85.3017 12.7142H93.285L88.5546 29.575ZM93.7191 11.1072H85.7358L87.0318 
            6.5H95.0151L93.7191 11.1072Z" fill="black"/>
            <path d="M108.066 12.7142L108.759 16.518H102.648L100.854 22.9201C100.698 23.4126 100.575 24.0347 100.575 
            24.4364C100.575 25.1816 100.886 25.4279 101.968 25.4279H103.672L102.499 29.575H95.3845C93.0323 29.575 92.138 
            28.4281 92.138 26.6979C92.138 25.9851 92.2287 25.214 92.4815 24.3457L96.81 8.97533H104.793L103.744 
            12.7207H108.072L108.066 12.7142Z" fill="black"/>
            <path d="M118.835 12.7142L114.507 22.0583L114.63 12.7142H106.556L108.105 29.575C107.91 30.56 107.58 
            31.1172 106.964 31.3959C106.284 31.7004 104.754 31.6421 104.217 31.6162H104.119L102.849 36.204H108.299C111.513 
            36.204 113.094 34.5905 115.382 31.0071L127.013 12.7272H118.848L118.835 12.7142Z" fill="black"/>
            </g>
            <path d="M238.538 24.812V26.428C237.386 27.34 235.962 27.82 234.426 27.82C231.066 27.82 228.602 25.356 228.602 
            21.836C228.602 18.332 231.082 15.852 234.41 15.852C235.866 15.852 237.29 16.284 238.346 17.052V18.62C237.082 
            17.596 235.866 17.068 234.538 17.068C231.898 17.068 230.074 19.132 230.074 21.836C230.074 24.54 231.882 26.62 234.554 
            26.62C235.93 26.62 237.322 26.028 238.538 24.812ZM246.914 18.172L244.082 27.5H242.706L239.314 16.172H240.818L243.426 
            25.452H243.474L246.21 16.172H247.682L250.482 25.452H250.53L253.138 16.172H254.578L251.17 27.5H249.81L246.946 
            18.172H246.914ZM256.182 16.172L259.67 25.868H259.702L263.206 16.172H264.694L260.374 27.5H258.934L254.662 16.172H256.182Z" 
            fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M212.692 10.8927C212.692 8.67566 214.498 6.87805 216.698 6.87805C218.898 
            6.87805 220.692 8.67566 220.692 10.8927C220.692 13.1097 218.898 14.9074 216.698 14.9074C214.486 14.9074 212.692 13.0978 
            212.692 10.8927ZM213.409 10.8921C213.409 12.7137 214.88 14.1877 216.698 14.1877C218.515 14.1877 219.986 12.7137 219.986 
            10.8921C219.986 9.07056 218.515 7.59653 216.698 7.59653C214.88 7.59653 213.409 9.07056 213.409 10.8921Z" fill="black" 
            fill-opacity="0.9"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M216.697 9.01056C217.552 9.01056 217.953 9.40294 217.953 10.1135C217.953 
            10.7497 217.582 11.0573 217.099 11.1209L218.025 12.6692H217.335L216.45 11.1739H215.976V12.6692H215.358V9.01056H216.697ZM215.987 
            9.58539V10.6034V10.6141H216.46C216.862 10.6141 217.304 10.5822 217.304 10.0944C217.304 9.59599 216.862 9.57478 216.46 
            9.58539H215.987Z" fill="black" fill-opacity="0.9"/>
            <path d="M154.723 19.5461H149.646V28.8999H147V7.11538H154.477C158.2 7.11538 161.861 8.5923 161.861 13.2076C161.861 17.423 
            158.631 19.5461 154.723 19.5461ZM154.569 9.39229H149.646V17.2692H154.661C156.877 17.2692 159.031 16.0384 159.031 
            13.2384C159.031 10.4384 156.754 9.39229 154.569 9.39229Z" fill="black"/>
            <path d="M178.575 8.43845V11.4846C176.544 9.69998 174.513 8.6846 172.39 8.6846C169.836 8.6846 168.083 10.1307 168.083 
            12.5615C168.083 15.0846 169.959 15.9461 172.575 16.7153C176.606 17.9153 179.036 19.5153 179.036 23.1153C179.036 27.2999 
            175.559 29.5152 171.744 29.5152C169.129 29.5152 166.913 28.6537 165.067 27.4845V24.2845C167.529 26.3768 169.713 27.3614 
            171.775 27.3614C174.483 27.3614 176.267 25.7614 176.267 23.3307C176.267 20.7461 174.083 19.7922 171.529 19.023C167.559 
            17.8845 165.344 16.2538 165.344 12.623C165.344 8.71537 168.575 6.5 172.544 6.5C175.006 6.5 176.975 7.3 178.575 8.43845Z" 
            fill="black"/>
            <path d="M195.967 10.9615L190.521 28.8999H187.874L181.351 7.11538H184.244L189.259 24.9614H189.351L194.613 
            7.11538H197.444L202.828 24.9614H202.921L207.936 7.11538H210.705L204.151 28.8999H201.536L196.028 10.9615H195.967Z" fill="black"/>
            <defs>
            <clipPath id="clip0_1379_1913">
            <rect width="127" height="29.704" fill="white" transform="translate(0 6.5)"/>
            </clipPath>
            </defs>
            </svg>
        `;
    }
    // this removes the shadow dom
    public createRenderRoot() {
        return this;
    }
}
