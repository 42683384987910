/*
 * Copyright 2021-23, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */
/**
 *  PlanSponsorExperience is used to check if experience is plansponsor and update css classed based on the condition
 */
export const PlanSponsorExperience = 'plansponsor';

export const PlanAdvisorExperience = 'planadvisor';

export const SimpleNavigationOnly = 'simpleNavigationOnly';

export const ParticipantSearch = 'participantSearch';

export const SettingsAndProfile = 'Settings & Profile';

export const SimpleNavLink = 'Simple Utility Link';

export const Profile = 'Profile';

export const Logout = 'Logout';

export const KeydownEscape = 'Escape';

export const MouseClick = 'click';

export const Enter = 'Enter';

export const SearchCompare = 'Search';

export const JS = 'JS';

export const HTML = 'HTML';

export const CSS = 'CSS';

export const SearchType = '/searchType/gi';

export const WhatToView = '/plansponsor/participant/ParticipantLanding.psw';

export const SSN = 'ssn';

export const NAME = 'name';

export const EMP_ID = 'EMPLOYEEID';

export const USER = 'userId';

export const CLIENT = 'clientId';

export const PSPUBLIC = 'PSPUBLIC';

export const PLANSPONSOR_CWV = 'plansponsor_cwv';

export const AnalyticsData = {
    site_events: {
        navbar_action: true
    },
    action_detail: 'tag',
    event_name: 'tag'
};
