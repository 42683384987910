/*
 * Copyright 2021-2023, FMR LLC.
 * All Rights Reserved.
 * Fidelity Confidential Information
 */

import {
    LitElement,
    html,
    TemplateResult,
    nothing
} from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { injectCss, FdAnalyticsService } from '@fmr-ap123285/dp-microfrontend-utils';
import ComponentStyles from './component.scss';
import { Subscription } from 'rxjs';
import { MenuGroups, MenuSubGroups, PageList, Utilities, TxnToken, UpdatePreferencesResponse,
    ParticipantSearchData } from '../../models/content';
import { Environment } from '../../../environments/environment';
import { KeydownEscape, Enter, Logout, Profile, SettingsAndProfile,
    SimpleNavigationOnly, SearchCompare, WhatToView, AnalyticsData, SSN, NAME,EMP_ID,
    ParticipantSearch,
    PLANSPONSOR_CWV,
    SimpleNavLink } from '../../constants/navbar.constants';
import { ParticipantSearchService } from '../../services/participant-search-service';
import { RecentlyViewedParticipantsComponent } from '../recently-viewed-participants/component';
/**
 * This is a feature of TypeScript and not specific to LitElement.
 * This declaration helps TypeScript to provide strong typing when interacting with DOM APIs.
 * The JavaScript DOM API of course does not know or care about types,
 * but TypeScript does. With this mechanism you can add the type of your custom elements to the DOM APIs
 */
declare global {
    interface HTMLElementTagNameMap {
        'psw-navbar-utility-nav': UtilityNavbar;
    }
}

/**
 * UtilityNavbar component that extends the LitElement base class.
 * @memberOf UtilityNavbar
 */
@injectCss(ComponentStyles)
@customElement('psw-navbar-utility-nav')

export class UtilityNavbar extends LitElement {
    /**
     * Links stored in this array
     * @memberOf UtilityNavbar
     */
    @property({
        type: Array
    })
    public utilitiesItems: Utilities[] = [];

    @property({
        type: Array
    })
    public megaMenuGroups: MenuGroups[] = [];

    @property({ type: String })
    public clientName: string = '';

    /**
     * Optional parameter to add link to Simple Utility Nav
     */
    @property({ type: { label: String, href: String } })
    public simpleUtilityLink: { label: string; href: string } = { label: '', href: '' };

    /**
     * internal property of Subscription
     * @memberOf UtilityNavbar
     */
    @state()
    public navigationDataSubscription: Subscription | null = null;

    @property({ attribute: true })
    public navbarData: string = '';

    @property({ type: String })
    public pswLogoTarget = '';

    @property({ type: Boolean })
    public simpleUtilityNavbar = false;

    @property({ type: Boolean })
    public enablePartSearch = false;

    @property({ type: Boolean })
    public cwvUtilityNavbar = false;

    /**
     * Boolean representing the True/False for the "isMegaMenuOpen"
     * This decorator should only be used for public fields.
     * Private or protected fields should use the state,
     * @memberOf UtilityNavbar
     */
    @property({ type: Boolean })
    public isMegaMenuOpen = false;

    @property({ type: Boolean })
    public renderDropdown = false;

    @property({ type: Boolean })
    public isLastMegaMenuLinkActive = false;

    @property({ type: String })
    public navbar = 'true';

    @property({ type: String })
    public txnToken = '';

    @query('#main_nav_togglemegamenu_id')
    public navbarElement?: HTMLElement;

    @query('#menu .utility-nav__hamburger-icon')
    public hamburgerElement?: HTMLElement;

    public lastMegaMenuPlaceholderLink?: HTMLElement;

    public dropdownItems: Utilities[] = [];

    /**
     * Object representing the responseData for the "Menu Items and Links"
     * @memberOf UtilityNavbar
     */
    public responseData!: Utilities;

    private _boundDocumentClickListener?: EventListener;
    private _boundKeydownListener?: EventListener;
    private _boundLastMegaMenuLinkKeyupListener?: EventListener;

    /**
     * CreateRenderRoot method : Removes the shadow dom
     * @memberOf UtilityNavbar
     */
    public createRenderRoot() {
        return this;
    }

    public connectedCallback() {
        super.connectedCallback();

        this._boundDocumentClickListener = (this.onDocumentClick as EventListener).bind(this);
        document.addEventListener('click', this._boundDocumentClickListener);

        this._boundKeydownListener = (this.onKeydown as EventListener).bind(this);
        document.addEventListener('keydown', this._boundKeydownListener);

        this._boundLastMegaMenuLinkKeyupListener = (this.onLastMegaMenuLinkKeyup as EventListener).bind(this);
        document.addEventListener('keyup', this._boundLastMegaMenuLinkKeyupListener);

        const navbarDataObject = JSON.parse(this.navbarData);
        const data = navbarDataObject?.siteNav?.navigationHeader;
        const utilityBundle = data?.utilityNavbar;
        const megaMenuBundle = data?.menu;
        const experience = navbarDataObject?.experience;

        const pageFeaturesList = navbarDataObject?.pageServices?.pageFeatures;

        for (const element of pageFeaturesList) {
            if (element.featureName === SimpleNavigationOnly) {
                this.simpleUtilityNavbar = true;
                break;
            }

        }

        for (const element of pageFeaturesList) {
            if (element.featureName === ParticipantSearch) {
                this.enablePartSearch = true;
                break;
            }

        }

        this.pswLogoTarget = utilityBundle?.logo?.target;
        utilityBundle?.utilities?.map((utility: Utilities) => {
            this.responseData = utility;
            this.utilitiesItems.push(this.responseData);
        });
        this.megaMenuGroups = megaMenuBundle?.menuGroups;

        this.clientName = this.utilitiesItems[0]?.page?.label;

        const simpleNavLink = this.utilitiesItems.find((element) => {
            return element.utility = SimpleNavLink;
        });

        this.simpleUtilityLink.label = simpleNavLink?.page?.label || '';

        this.simpleUtilityLink.href = simpleNavLink?.page?.link?.target || '';

        if (experience === PLANSPONSOR_CWV) {
            this.cwvUtilityNavbar = true;
        }

        if (this.enablePartSearch) {
            this.constructParticipantSearchOptions();
        }
    }

    public disconnectedCallback() {
        this.removeDocumentClickListener();
        this.removeKeydownListener();
        super.disconnectedCallback();
    }

    /**
     * This function is a Lit library lifecycle hook. It is called after the component's DOM has been updated the first time.
    */
    public firstUpdated() {

        const allMegaMenuLinks = document.querySelectorAll('.main-nav .main-nav__link');
        const lastMegaMenuLink = allMegaMenuLinks[allMegaMenuLinks.length - 1];
        this.lastMegaMenuPlaceholderLink = document.createElement('a');
        this.lastMegaMenuPlaceholderLink.setAttribute('href', '');
        this.lastMegaMenuPlaceholderLink.style.outline = 'none';
        lastMegaMenuLink.parentNode?.insertBefore(this.lastMegaMenuPlaceholderLink, lastMegaMenuLink.nextSibling);

    }

    /**
     * Set focus on MegaMenu toggle button when user tabs away from last mega menu link.
     * @memberOf UtilityNavbar
     */
    public onLastMegaMenuLinkKeyup(e: KeyboardEvent) {
        if (!this.isLastMegaMenuLinkActive) {
            this.getLastMegaMenuLinkFocusState();
        }
        if (this.isLastMegaMenuLinkActive && e.code === 'Tab') {
            this.focusOnMegaMenuToggleBtn();
        }
    }

    public getLastMegaMenuLinkFocusState(): void {
        this.isLastMegaMenuLinkActive = document.activeElement === this.lastMegaMenuPlaceholderLink;
    }

    /**
     * Focus on MegaMenu Toggle Button
     * @memberOf UtilityNavbar
     */
    public focusOnMegaMenuToggleBtn(): void {
        document.getElementById('menu')?.focus();
        this.isLastMegaMenuLinkActive = false;
    }

    /**
     * This method is used to construct the participant search dropdown based on what options the user is
     *  enabled to use. Then, we set the user's preferred option as default selected option
     * @memberOf UtilityNavbar
     */
    public constructParticipantSearchOptions() {
        ParticipantSearchService.getParticipantSearchOption().subscribe({
            next: (resp : ParticipantSearchData) => {
                // Declare participant search dropdown menu and search options
                const participantdropdownMenu = document.getElementById('participant-search-dropdown') as HTMLSelectElement;
                const searchOptionsArray = resp.data.options;

                // Check which participant dropdown search options the user is entitled to, whenever
                //  the user is NOT enabled to see all 3 search options
                if (searchOptionsArray.length < 3) {
                    for (let i = 0; i < participantdropdownMenu.length; i++) {
                        if (participantdropdownMenu.options[i].value === 'default') {
                            continue;
                        } else if (!searchOptionsArray.includes(participantdropdownMenu.options[i].value)) {
                            participantdropdownMenu.remove(i);
                        }
                    }
                }

                // Only update the dropdown menu preference if the api call is successful
                if (resp.status.code === 200 && participantdropdownMenu.value !== resp.data.preference) {
                    participantdropdownMenu.value = resp.data.preference;
                }
            },
            error: (error) => {
                console.error(error);
            }
        });
    }


    public onDocumentClick(event: MouseEvent): void {
        const target: HTMLElement = event.target as HTMLElement;
        // only close nav if click is outside of nav
        if (this.isMegaMenuOpen && target.closest('.main-nav--open') === null &&
            target.closest('.main-nav__menus') === null && target.closest('#menu') === null) {
            this.closeMegaMenu();
        }
        return;
    }

    /**
     * Open/Close MegaMenu when hamburger icon clicked
     * @memberOf UtilityNavbar
     */
    public onHamburgerClicked(e: MouseEvent) {
        if (this.renderDropdown === true) {
            this.renderDropdown = false;
        }
        this.navbarElement?.classList.toggle('main-nav--open');
        this.hamburgerElement?.classList.toggle('utility-nav__hamburger-icon--open');
        this.isMegaMenuOpen = !this.isMegaMenuOpen;
    }

    /**
     * Open/Close MegaMenu when escape key pressed
     * @memberOf UtilityNavbar
     */
    public onKeydown(e: KeyboardEvent) {
        if (this.isMegaMenuOpen && e.code === KeydownEscape) {
            this.closeMegaMenu();
        }
    }

    /**
     * Close MegaMenu
     * @memberOf UtilityNavbar
     */
    public closeMegaMenu(): void {
        if (this.isMegaMenuOpen === true) {
            this.hamburgerElement?.classList.remove('utility-nav__hamburger-icon--open');
            this.navbarElement?.classList.remove('main-nav--open');
            this.isMegaMenuOpen = false;
            document.getElementById('menu')?.focus();
        }
    }

    /**
     * Open MegaMenu
     * @memberOf UtilityNavbar
     */
    public openMegaMenu(): void {
        this.hamburgerElement?.classList.add('utility-nav__hamburger-icon--open');
        this.navbarElement?.classList.add('main-nav--open');
        this.isMegaMenuOpen = true;
    }


    public userProfileDropdown() {
        // close megamenu when profile dropdown is clicked
        if (this.isMegaMenuOpen) {
            this.isMegaMenuOpen = false;
        }
        const searchItems = [SettingsAndProfile, Profile, Logout];
        this.dropdownItems = [];
        for (let i = 0; i < this.utilitiesItems.length; i++) {
            if (searchItems.includes(this.utilitiesItems[i].page.label)) {
                this.dropdownItems.push(this.utilitiesItems[i]);
            }
        }
        this.renderDropdown = !this.renderDropdown;
    }

    /**
     * This function is responsible for emit custom events from this element
     * @param name {string} name - name of the page
     * @param data
     * @memberOf UtilityNavbar
     */
    public _dispatchEvent(name: string, data?: unknown) {
        const event = new CustomEvent(`${name}`, {
            detail: data
        });
        this.dispatchEvent(event);
    }



    /**
     * This function is responsible for slecting of Participant search option & value
     * @memberOf UtilityNavbar
     */
    public searchParticipants(event: KeyboardEvent) {
        if (event.key === Enter) {
            this.searchSelection();
        }
    }

    /**
     * This function is responsible for slecting of Participant search option & value
     * @memberOf UtilityNavbar
     */
    public searchSelection() {
        const searchOption = (document.getElementById('participant-search-dropdown') as HTMLInputElement).value;
        const userInput = (document.getElementById('searchTxt') as HTMLInputElement).value;
        let searchType = '';
        if (userInput !== null || userInput !== '') {
            switch (searchOption) {
            case 'ssn': {
                searchType = SSN;
                break;
            }
            case 'name': {
                searchType = NAME;
                break;
            }
            case 'empid': {
                searchType = EMP_ID;
                break;
            }
            }
        }
        ParticipantSearchService.getCSRFToken().subscribe({
            next: (res: TxnToken) => {
                this.txnToken = res.txnTokenValue;
                ParticipantSearchService.updatePreferences(searchOption).subscribe(
                    {
                        next: (resp: UpdatePreferencesResponse) => {
                            this.participantSearch(searchType, userInput);
                        }
                    }
                );
            }
        });
    }

    /**
     * Participant Search
     * @memberOf UtilityNavbar
     */
    public participantSearch(selectedOption: string, userInput: string) {
        const form = document.createElement('form');
        form.name = 'searchForm';
        form.method = 'post';
        form.action = Environment.partSearchLanding;

        const txnTokenHeader = document.createElement('input');
        txnTokenHeader.name = 'txntoken';
        txnTokenHeader.value = this.txnToken;
        txnTokenHeader.type = 'hidden';
        form.appendChild(txnTokenHeader);

        const hiddenWhatToView = document.createElement('input');
        hiddenWhatToView.name = 'whatToView';
        hiddenWhatToView.value = WhatToView;
        hiddenWhatToView.type = 'hidden';
        form.appendChild(hiddenWhatToView);

        const hiddenSearchValue = document.createElement('input');
        hiddenSearchValue.name = 'searchValue';
        hiddenSearchValue.type = 'hidden';
        hiddenSearchValue.value = userInput;
        form.appendChild(hiddenSearchValue);

        const hiddenSearchType = document.createElement('input');
        hiddenSearchType.name = 'searchType';
        hiddenSearchType.type = 'hidden';
        hiddenSearchType.value = selectedOption;
        form.appendChild(hiddenSearchType);

        document.body.appendChild(form);
        form.submit();
    }

    public submitAnalytics(e: Event) {
        const eventTargetElement: Element = e.target as Element;
        const tag: string = eventTargetElement.id;
        const analyticsData = AnalyticsData;
        analyticsData.action_detail = tag;
        analyticsData.event_name = 'navbar action:' + tag;
        FdAnalyticsService.submitAnalytics(analyticsData);
    }

    /**
     * The render function defines a template literal and
     * makes use of the utility navbar element using this below template as if it were part of the HTML
     * @memberOf UtilityNavbar
     */
    public render(): TemplateResult {
        return html` <div class="mfe-root-container">
            <header class="psw-navigation" >
                <div>
                    <a href="#skipToContent" tabindex="1" id="navbar_skipNav">
                        Skip to main content
                    </a>
                </div>
                <nav>
                    <div class="utility-nav-container">
                        <section
                            aria-label="Utility Navigation"
                            aria-hidden="false"
                            class="utility-nav">
                            <div class="utility-nav__left">
                                ${this.simpleUtilityNavbar ? nothing : html`<button id="menu"
                                    class="utility-nav__reveal-button"
                                    aria-label="Psw Navigation Menu"
                                    @click="${this.onHamburgerClicked}"
                                    aria-expanded="${this.isMegaMenuOpen}">

                                    <span class="utility-nav__hamburger-icon" aria-hidden="true">
                                        <span class=${this.cwvUtilityNavbar ? 'customerWebView' : nothing}></span>
                                        <span class=${this.cwvUtilityNavbar ? 'customerWebView' : nothing}></span>
                                        <span class=${this.cwvUtilityNavbar ? 'customerWebView' : nothing}></span>
                                    </span>
                                </button>`}
                                ${this.simpleUtilityNavbar ? nothing :
                html`
                <nav class="main-nav main-nav-container" id="main_nav_togglemegamenu_id">
                                <ul class="main-nav__items" aria-label="Primary Navigation" tabindex="-1">
                                    ${this.megaMenuGroups.map((megaMenuItem: MenuGroups) => html`
                                        <li class="main-nav__item">
                                            <psw-navbar-pvd-expand-collapse pvd-size="medium" class="main-nav__menus--mobile">
                                                <span slot="heading" class="pvd-expand-collapse__heading main-nav__title">
                                                ${megaMenuItem.groupName}</span>
                                                <div class="pvd-expand-collapse__content">
                                                    <ul class="main-nav__menu" id=${megaMenuItem.groupName}>
                                                    ${megaMenuItem.menuSubGroups.map((megaMenuSubGroupItem: MenuSubGroups) => html`
                                                    <li class="main-nav__menu-item" id=${megaMenuSubGroupItem.subGroup}>
                                                        <h3 class="main-nav__submenu-title">${megaMenuSubGroupItem.subGroup}</h3>
                                                        <ul class="main-nav__submenu">
                                                        ${megaMenuSubGroupItem.pageList.map((pageListLink: PageList) => html`
                                                        ${pageListLink.link === null ?
                        nothing : html`
                                                    <li>
                                                    ${pageListLink.targetType === 'popup' ? html`
                                                    <a id= "${megaMenuItem.groupName}:${megaMenuSubGroupItem.subGroup}:
                                                                                      ${pageListLink.label}"
                                                    href="javascript:(window.open('${pageListLink.link.target}','${pageListLink.popupName}',
                                                    'height=800,width=560,scrollbars=1,resizable=1').focus());" class="main-nav__link"
                                                     @click="${this.submitAnalytics}">${pageListLink.label}
                                                    <img class="popupIcon" src="/bin-public/06_PSW_Website/images/popup.gif"
                                                    alt="Opens in new window." />
                                                    </a>`: html`
                                                    <a id= "${megaMenuItem.groupName}:${megaMenuSubGroupItem.subGroup}:
                                                                                      ${pageListLink.label}"
                                                    href=${pageListLink.link.target} class="main-nav__link"
                                                    @click="${this.submitAnalytics}">
                                                    ${pageListLink.label}</a>`}
                                                    </li>`}`)}
                                                    ${(megaMenuSubGroupItem.subGroup === SearchCompare && this.enablePartSearch === true) ?
                                            RecentlyViewedParticipantsComponent.renderedHtml : nothing}
                                                        </ul>
                                                    </li>
                                                        `)}
                                                    </ul>
                                                </div>
                                            </psw-navbar-pvd-expand-collapse>

                                            <div class="main-nav__menus">
                                                <div class="main-nav__heading">
                                                    <h2 class="main-nav__title">${megaMenuItem.groupName}</h2>
                                                </div>
                                                <ul class="main-nav__menu" id=${megaMenuItem.groupName}>
                                                ${megaMenuItem.menuSubGroups.map((megaMenuSubGroupItem: MenuSubGroups) => html`
                                                <li class="main-nav__menu-item" id=${megaMenuSubGroupItem.subGroup}>
                                                <h3 class="main-nav__submenu-title"
                                                aria-label="${megaMenuItem.groupName} ${megaMenuSubGroupItem.subGroup}">
                                                ${megaMenuSubGroupItem.subGroup}</h3>
                                                <ul class="main-nav__submenu">
                                                ${megaMenuSubGroupItem.pageList.map((pageListLink: PageList) => html`
                                                ${pageListLink.link === null ?
                                nothing : html`
                                                <li>
                                                ${pageListLink.targetType === 'popup' ? html`
                                                <a id= "${megaMenuItem.groupName}:${megaMenuSubGroupItem.subGroup}:${pageListLink.label}"
                                                aria-label="${megaMenuItem.groupName}
                                                            ${megaMenuSubGroupItem.subGroup}
                                                            ${pageListLink.label}"
                                                href="javascript:(window.open('${pageListLink.link.target}','${pageListLink.popupName}',
                                                'height=800,width=560,scrollbars=1,resizable=1').focus());" class="main-nav__link"
                                                @click="${this.submitAnalytics}">${pageListLink.label}
                                                <img class="popupIcon" src="/bin-public/06_PSW_Website/images/popup.gif"
                                                alt="Opens in new window." />
                                                </a>`: html`
                                                <a id= "${megaMenuItem.groupName}:${megaMenuSubGroupItem.subGroup}:${pageListLink.label}"
                                                aria-label="${megaMenuItem.groupName}
                                                            ${megaMenuSubGroupItem.subGroup}
                                                            ${pageListLink.label}"
                                                href=${pageListLink.link.target} class="main-nav__link" @click="${this.submitAnalytics}">
                                                ${pageListLink.label}</a>`}
                                                </li>`}`)}
                                                ${(megaMenuSubGroupItem.subGroup === SearchCompare && this.enablePartSearch === true) ?
                                html`<psw-recently-viewed-participants></psw-recently-viewed-participants>` : nothing}
                                                        </ul>
                                                    </li>
                                                    `)}
                                                </ul>
                                            </div>
                                        </li>
                                    `)}
                                </ul>
                            </nav>`}
                                ${this.simpleUtilityNavbar ? html`
                                    <span class="utility-nav__home-icon" aria-hidden="true">
                                        ${this.cwvUtilityNavbar ? html`
                                        <psw-cwv-logo id="psw_cwv_logo" aria-hidden="true"></psw-cwv-logo>`
                                        : html`
                                        <psw-logo id="psw_logo" aria-hidden="true"></psw-logo>`}
                                    </span>
                                ` : html`<a
                                    href=${this.pswLogoTarget}
                                    class="utility-nav__title nav-item__link">
                                    <span class="utility-nav__home-icon" aria-hidden="true">
                                        ${this.cwvUtilityNavbar ? html`
                                        <psw-cwv-logo id="psw_cwv_logo" aria-hidden="true"></psw-cwv-logo>`
                                        : html`
                                        <psw-logo id="psw_logo" aria-hidden="true"></psw-logo>`}
                                    </span>
                                </a>`}
                            </div>

                            ${(this.simpleUtilityNavbar || this.enablePartSearch === false) ? nothing : html`
                            <div class="filter-section">
                                <div class="participant-dropdown-search-wrapper">
                                   <psw-navbar-pvd-field-group class="participant-dropdown-wrapper">
                                        <psw-navbar-pvd-select
                                            pvd-id="participant-search-dropdown"
                                            pvd-aria-label="Participant Search Options"
                                            pvd-options='[{"value":"default","text":"Select","disabled":true},
                                                        {"value":"name", "text":"Participant Name"},
                                                        {"value":"ssn","text":"Participant SSN"},
                                                        {"value":"empid","text":"Employee ID"}]'>
                                        </psw-navbar-pvd-select>
                                    </psw-navbar-pvd-field-group>
                                    <div class="search">
                                        <i class="icon" @click="${this.searchSelection}">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 30 30" width="20px" height="20px">
                                        <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508
                                        23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0
                                        26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23
                                        7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123
                                        17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/></svg>
                                        </i>
                                        <input id="searchTxt" class="textbox" type="text" @keyup="${this.searchParticipants}"
                                        placeholder="Search Participants"></input></div>
                                </div>
                            </div>`}

                            ${this.simpleUtilityNavbar ? html`
                             ${this.simpleUtilityLink.label ? html
                                `<div class="utility-nav__menu-container">
                                    <psw-navbar-pvd-link class="simple-utility-nav__nav-item-text"
                                            pvd-href=${this.simpleUtilityLink.href}>${this.simpleUtilityLink.label}
                                        </psw-navbar-pvd-link>
                                </div>` : nothing }
                            ` : html`
                            <div class="utility-nav__menu-container">

                                <psw-navbar-pvd-link class="utility-nav__nav-item-text"
                                    pvd-href=${this.utilitiesItems[0]?.page?.link?.target}>${this.clientName}
                                </psw-navbar-pvd-link>
                                
                                <ul class=${this.cwvUtilityNavbar ? 'utility-nav__nav-items customerWebView' : 'utility-nav__nav-items'}>
                                        ${this.utilitiesItems.map((utilityItem: Utilities) => html`
                                    <li class="utility-nav__nav-item utility-nav__menu-items utility-nav__menu-link">
                                        ${utilityItem.decorator.image !== '' && utilityItem.page.label !== SettingsAndProfile ? html`
                                        <a class="utility-nav__nav-item-link" href="${utilityItem.page.link.target}">
                                            <psw-navbar-pvd-icon pvd-name="${utilityItem.decorator.image}"
                                                pvd-a11y-label="${utilityItem.utility}"
                                                pvd-size="large">
                                            </psw-navbar-pvd-icon>
                                        </a>`: nothing}
                                        ${utilityItem.decorator.image !== '' && utilityItem.page.label === SettingsAndProfile ? html`
                                        <div>
                                            <button @click="${this.userProfileDropdown}" class="dropbtn"
                                            aria-expanded="${this.renderDropdown}" aria-label="${utilityItem.utility}">
                                                <psw-navbar-pvd-icon pvd-name=${utilityItem.decorator.image}
                                                    pvd-a11y-label=${utilityItem.utility}
                                                    pvd-size="large">
                                                </psw-navbar-pvd-icon>
                                            </button>
                                            ${this.renderDropdown ? html`
                                            <div class="dropdown-content">
                                            ${this.dropdownItems.map((item: Utilities) => html`
                                                ${item.page.label === SettingsAndProfile ? html`
                                                <span class="dropdown-profile">${item.page.label}
                                                </span>
                                                `: html`<li><a class=${item.page.label === Logout ? 'dropdown-logout' : 'hover-highlight'}

                                                href=${item.page.link.target}>${item.page.label}</a></li>`}
                                            `)}
                                            </div>
                                            `: nothing}
                                        </div>`: nothing}
                                    </li>`)}
                                </ul>
                            </div>`}
                        </section>
                    </div>
                </nav>
                <div id="skipToContent"></div>
            </header>
        </div>`;
    }

    /**
     * Removes the click listener
     */
    private removeDocumentClickListener(): void {
        if (this._boundDocumentClickListener) {
            document.removeEventListener('click', this._boundDocumentClickListener);
            this._boundDocumentClickListener = undefined;
        }
    }

    /**
     * Removes the keydown listener
     */
    private removeKeydownListener(): void {
        if (this._boundKeydownListener) {
            document.removeEventListener('keydown', this._boundKeydownListener);
            this._boundKeydownListener = undefined;
        }
    }

}
